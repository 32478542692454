import * as React from 'react';
import Auth from '../services/auth';
import { Redirect, Route } from 'react-router'



const PrivateRoute = ({ component: Component, ...rest }) => {

    // Add your own authentication on the below line.
    const auth = new Auth();
    const isLoggedIn = auth.isAuthenticated();
    const isValidLogin = auth.isValidLogin();

    //

    console.log('privateroute', (isLoggedIn && isValidLogin));
    return (
        <Route
            {...rest}
            render={props =>
                isLoggedIn && isValidLogin ? 
                    <Component isAuthenticated={(isLoggedIn && isValidLogin)} {...props} {...rest}/>
                 :
                    <Redirect to={{ pathname: '/login', state: { from: props.location } }} />

            }
        />
    )
}

export default PrivateRoute