import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Typography, IconButton } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Auth from '../services/auth';

import EntityTable from "./EntityTable";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    container: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'left',
        color: theme.palette.text.secondary,
    }
}));

const columns = [
    { id: 'account', label: 'Account', disableSorting: true },
    { id: 'dictatorId', label: 'Doctor', disableSorting: true },
    { id: 'rank', label: 'Rank', disableSorting: true },
    { id: 'timestamp', label: 'Created', disableSorting: false, type: 'date' }
];

export default function DoctorProfiles(props) {
    const classes = useStyles();
    const auth = new Auth();
    const [rows, setRows] = useState([]);

    const userHasUpdatePermission = auth.isInRole('SNChartGlobalAdmin') || auth.isInRole('SNChartSuperUser');
    const [dataFetching, setDataFetching] = useState(false);

    const addDoctorProfile = () => {
        const doctorProfile = "doctorprofile";
        props.history.push(`/${doctorProfile}/new/0`);
    }

    useEffect(() => {
        async function fetchDataGlobal() {
            // You can await here
            setDataFetching(true);
            const response = await fetch('DoctorProfiles/GetAll');
            const data = await response.json();
            console.log(data);
            setRows(data);
            setDataFetching(false);
        }
        fetchDataGlobal();
    }, []);

    return (
        <Paper className={classes.paper}>
            <div className={classes.container}>
                <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12}>
                        {
                            <Typography variant="h6">Doctor Profiles </Typography>
                        }
                        <IconButton
                            variant="contained"
                            color="primary"
                            onClick={() => addDoctorProfile()}
                            disabled={!userHasUpdatePermission}
                        >
                            <AddBoxIcon />
                        </IconButton>
                        <EntityTable
                            rows={rows}
                            columnHeaders={columns}
                            entityName={'doctorprofile'}
                            customDeletePath={'DoctorProfiles/DeleteDoctorProfile'}
                            updateSubEntity={userHasUpdatePermission}
                            deleteSubEntity={userHasUpdatePermission}
                            fetching={dataFetching}
                        />
                    </Grid>
                </Grid>
            </div>
        </Paper>
    );
}