import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Typography, AppBar } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Auth from '../services/auth';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import LinearProgress from '@material-ui/core/LinearProgress';
import DocumentViewer from './common/DocumentViewer';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getDraftReport, getComparedDocument } from '../services/Report/reportService';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    container: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },
    bottomSpace: {
        paddingBottom: '20px'
    }
}));

export default function Test(props) {
    const classes = useStyles();
    const auth = new Auth();
    const [test, setTest] = React.useState({ displayName: "", status: "", customModel1Name: "", text: "", queueTime: "" });
    const [isEvaluationRerun, setEvaluationRerun] = React.useState(false);
    const [openSnack, setOpenSnack] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("warning");
    const [loading, setLoading] = useState(false);
    const [loadingDraft, setLoadingDraft] = useState(false);
    const [doc, setDoc] = useState();
    const [tabValue, setTabValue] = React.useState('1');

    const handleTabChange = (event, newValue) => {
        //console.log('handleTabChange', newValue);
        setTabValue(newValue);
    };
    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        props.history.push('/tests');
        setOpenSnack(false);
    };
    const rerunEvaluation = async (row) => {
        await setEvaluationRerun(true);
        const response = await fetch('api/Evaluations/RerunEvaluation', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ locale: row.locale, displayName: row.displayName, model1: row.model1, model2: row.model2, dataset: row.dataset, description: "trainer", self: row.self }) // body data type must match "Content-Type" header
        });
        await setEvaluationRerun(false);

        if (response.ok) {
            setSeverity("info");
            setMessage("Successfully Rerun Test");
            setOpenSnack(true)
        }
        else {
            setSeverity("error");
            setMessage("An error has occurred, please try again");
            setOpenSnack(true)
        }
    }


    useEffect(() => {

        async function fetchData() {
            // You can await here
            setLoading(true);
            setLoadingDraft(true);
            const response = await fetch(`api/Evaluations/GetEvaluation/${props.match.params.testid}`);
            const data = await response.json();
            var test = data.test;
            test.displayName = test.displayName.split(".")[0];
            setTest(test);
            setLoading(false);
            var jobId = test.displayName;
            var account = jobId.split('-')[0];

            const { data: draftreport } = await getDraftReport(jobId);
            const { data: comparedResult } = await getComparedDocument(account, jobId, draftreport);
            let reportHtml = decodeURIComponent(escape(atob(comparedResult)));
            setLoadingDraft(false);
            setDoc(reportHtml);
            
            
        }
        fetchData();

    }, []);




    return (
        <div className={classes.container}>
            <Grid item xs={12}>

                <Paper className={classes.paper}>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={10}>

                                <Grid item xs={12}>
                                    <Typography className={classes.bottomSpace} variant="h4">{test.displayName}</Typography>
                                    <div className={classes.bottomSpace}>Test Version of Courney: <b> {test.customModel1Name}</b> </div>
                                    <br />
                                   
                                    <TabContext value={tabValue} >
                                        <AppBar position="static" color="secondary">
                                            <TabList onChange={handleTabChange}>
                                            <Tab label="Evaluation" value="1" />
                                            <Tab label="Draft Comparison" value="2" />
                                            </TabList>
                                            </AppBar>
                                        <TabPanel value="1">
                                        {loading &&
                                            <CircularProgress />
                                        }
                                        {!loading &&
                                            <DocumentViewer documentHtml={test.text} />
                                        }
                                    </TabPanel>
                                        <TabPanel value="2">
                                        {loadingDraft &&
                                            <CircularProgress />
                                        }
                                        {!loadingDraft &&
                                            <DocumentViewer documentHtml={doc} />
                                        }
                                    </TabPanel>
                                  </TabContext>
                                    <br />

                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} justify="flex-end">
                            <Grid item >
                                <Button
                                    className={classes.textField}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => { rerunEvaluation(test) }}
                                >
                                    Re-test</Button>
                            </Grid>
                            <Grid item >
                                <Button
                                    className={classes.textField}
                                    variant="contained"
                                    onClick={() => { props.history.push('/tests'); }}
                                >
                                    Back</Button>
                            </Grid>

                        </Grid>
                        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openSnack} autoHideDuration={2000} onClose={handleCloseSnack}>
                            <Alert onClose={handleCloseSnack} severity={severity}>
                                {message}
                            </Alert>
                        </Snackbar>
                    </Grid>
                    {isEvaluationRerun ? <div style={{
                        backgroundColor: 'rgba(255, 255, 255, .5)',
                        position: 'absolute',
                        zIndex: 2000,
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0
                    }}>
                        <LinearProgress />
                    </div> : null}
                </Paper>
                
            </Grid>
        </div>
    );
}