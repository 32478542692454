import React, {useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Typography, Paper, IconButton} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Auth from '../services/auth';
import { API_ROOT } from '../services/apiConfig';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import LinearProgress from '@material-ui/core/LinearProgress';
import SearchBar from "material-ui-search-bar";

//Custom table
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import CreateIcon from '@material-ui/icons/Create';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

//Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import useTable from "./useTable";


const filterOptions = createFilterOptions({
    limit: 1500
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: "100%"
    },
    paper: {
        height: '100%',
        width: '100%',
        padding: theme.spacing(2),
        color: theme.palette.text.secondary
    },
    control: {
        padding: theme.spacing(2),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100%',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    dividerPadding: {
        marginTop: 20,
        marginBottom: 20,
        marginLeft: -2
    }
}));

const getRoutingColumns = () => {
    return [
        { id: 'edit', label: '', disableSorting: true },
        { id: 'dictatorId', label: 'Doctor ID', disableSorting: true },
        { id: 'physician', label: 'Doctor Name', disableSorting: true },
        { id: 'editor', label: 'Editor', disableSorting: true },
        { id: 'proofer', label: 'Proofer', disableSorting: true },
        { id: 'routing', label: 'Routing?', disableSorting: true },
        { id: 'courtney', label: 'Courtney?', disableSorting: true },
        { id: 'history', label: 'History?', disableSorting: true },
        { id: 'hasNormals', label: 'Has Normals', disableSorting: true },
        { id: 'lastModified', label: 'Last Modified', disableSorting: false, type: 'date' },
        { id: 'lastModifiedBy', label: 'Last Modified By', disableSorting: true }
    ]
};

export default function DictatorRoute(props){
    const classes = useStyles();
    const [dictatorRoute, setDictatorRoute] = React.useState({ account: "", dictatorId: "", editorId: "", proofer: "", doctors: []});
    const [errors, setErrors] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [severity, setSeverity] = React.useState("warning");
    const [isSaving, setIsSaving] = React.useState(false);
    const isGlobal = props.location.state.isGlobal || false;
    const [accounts, setAccounts] = useState([]);
    const [value, setValue] = React.useState("");
    const [inputValue, setInputValue] = React.useState('');

    const [doctors, setDoctors] = useState([]);
    const [doctorValue, setDoctorValue] = React.useState("");
    const [doctorInputValue, setDoctorInputValue] = React.useState('');
    const [selectedDoctor, setSelectedDoctor] = React.useState({});
    const [openDoctorDialog, setOpenDoctorDialog] = useState(false);
    const [isDictatorLoading, setDictatorLoading] = React.useState(false);
    const [editors, setEditors] = React.useState([]);
    const [editorValue, setEditorValue] = React.useState("");
    const [editorInputValue, setEditorInputValue] = React.useState('');
    const [proofers, setProofers] = React.useState([]);
    const [prooferValue, setProoferValue] = React.useState("");
    const [prooferInputValue, setProoferInputValue] = React.useState('');
    const [searched, setSearched] = React.useState("");

    const requestSearch = (searchedVal) => {
        const filteredRows = rows.filter((row) => {
            return row.physician.toLowerCase().includes(searchedVal.toLowerCase());
        });
        setFilteredRows(filteredRows);
    };

    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };

    //CUSTOM TABLE IMPLEMENTATION

    const [rows, setRows] = useState([]);

    const [filteredRows, setFilteredRows] = useState(undefined);
    const {
        TblContainer,
        TblHead,
        TblPagination,
        sortedRows
    } = useTable(rows, getRoutingColumns(), filteredRows);

    //END TABLE IMPLEMENTATION


    //START DOCTOR DIALOG IMPLEMENTATION
    const handleCloseDoctorDialog = () => {
        setOpenDoctorDialog(false);
    };

    const handleCheckboxOnChange = event => {
        const { name, checked } = event.target;
        setSelectedDoctor({ ...selectedDoctor, [name]: checked });
    };

    const handleSaveDoctor = async () => {
        let doctorsState = [...rows];
        let index = doctorsState.findIndex(d => d.id === selectedDoctor.id);
        if (index !== -1) {
            doctorsState.splice(index, 1);
        }
        doctorsState.push(selectedDoctor);
        doctorsState.sort((a, b) => a.id < b.id ? -1 : (a.id > b.id ? 1 : 0))
        setRows(doctorsState);
        setDictatorRoute({ ...dictatorRoute, doctors: doctorsState });
        cancelSearch();
        setOpenDoctorDialog(false);
    };

    const handleOnChangeDoctor = event => {
        const { name, value } = event.target;
        
        setSelectedDoctor({ ...selectedDoctor, [name]: value });
    };
    //END DOCTOR DIALOG IMPLEMENTATION
    const handleOnChange = event => {
        const { name, value } = event.target;
        if (value === "") {
            setErrors([...errors, { field: name, errorMessage: "empty value" }]);
        }
        else {
            setErrors(errors.filter((error) => { return error.field !== name }))
        }
        setDictatorRoute({ ...dictatorRoute, [name]: value });
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        if (isGlobal)
            props.history.push(`/accountrouting`);
        else 
            props.history.push(`/editorrouting`);
    };

    const isEnabled = dictatorRoute.account.length > 0;

    const SaveDictatorRoute = async () => {
        //let doctorsState = [...rows];
        //setDictatorRoute({ ...dictatorRoute, doctors: doctorsState });

        setIsSaving(true);
        const response = await fetch('DictatorRoutes/SaveDictatorRoute', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    Account: dictatorRoute.account,
                    DictatorId: isGlobal ? "-1" : dictatorRoute.dictatorId,
                    EditorId: dictatorRoute.editorId,
                    RowKey: dictatorRoute.rowKey ? dictatorRoute.rowKey : "",
                    PartitionKey: dictatorRoute.partitionKey ? dictatorRoute.partitionKey : "",
                    Proofer: dictatorRoute.proofer,
                    Doctors: rows
                }
            )
            
        });
        if (response.ok) {
            setMessage("Sucessfully saved!");
            setSeverity("info");
            setOpen(true);
        } else {
            setMessage("An error has Occurred!");
            setSeverity("error");
            setOpen(true);
        }
        setIsSaving(false);
    }

    const getDoctor = async (account, dictatorId) => {
        const auth = new Auth();
        let accounts = [account];
        const response = await fetch(`${API_ROOT}/api/v1/Users/AvailableDictatorsForAccount`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.getToken()}`
            },
            body: JSON.stringify(accounts)
        });
        let dictators = await response.json();
        let result = dictators.find(a => { return a.DictatorId === dictatorId });
        if (response.ok) {
            setDoctorValue(result);
        }
    }

    const getAccount = async (accountId) => {
        const auth = new Auth();
        const response = await fetch(`${API_ROOT}/api/v1/Users/Account?accountId=${accountId}`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.getToken()}`
            }
        });
        let data = await response.json();
        let formateddata = JSON.parse(data);
        if (response.ok) {
            setValue(formateddata);
        }
    }

    const getAccounts = async () => {
        const auth = new Auth();
        const response = await fetch(`${API_ROOT}/api/v1/Users/Accounts`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.getToken()}`
            }
        });
        let data = await response.json();
        let formateddata = JSON.parse(data);
        if (response.ok) {
            setAccounts(formateddata);
        }
    }

    const getDoctors = async (account) => {
        const auth = new Auth();
        let accounts = [account];
        const response = await fetch(`${API_ROOT}/api/v1/Users/AvailableDictatorsForAccount`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.getToken()}`
            },
            body: JSON.stringify(accounts)
        });
        let data = await response.json();
        if (response.ok) {
            let enhancedDoctors = addPropertiesToDoctors(data);
            setRows(data);
            //console.log(enhancedDoctors);
        }
    }

    const getMT = async () => {
        const auth = new Auth();
        let qTypeParams = {Account: 'sql', Command: 'GetMT', Parameters: []};
        const response = await fetch(`${API_ROOT}/api/v1/Qtype/Query`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.getToken()}`
            },
            body: JSON.stringify(qTypeParams)
        });
        let allDataResults = await response.json();

        let editors = allDataResults;
        let proofers = allDataResults.filter(a => { return a.Proof == 1 });
        if (response.ok) {
            setEditors(editors);
            setProofers(proofers);
        }
    }

    const addPropertiesToDoctors = (doctors) => {
        return doctors.forEach((doctor,index) => {
            doctor.editor = "";
            doctor.proofer = "";
            doctor.routing = true;
            doctor.courtney = false;
            doctor.history = false;
            doctor.hasNormals = "";
            doctor.lastModified = "";
            doctor.lastModifiedBy = "";
            doctor.id = index;
            doctor.rowkey = "";
            doctor.partitionKey = "";
            doctor.account = doctor.Account;
            doctor.dictatorId = doctor.DictatorId;
            doctor.physician = doctor.Physician;
            doctor.shard = doctor.Shard;

            delete doctor.Physician;
            delete doctor.DictatorId;
            delete doctor.Account;
            delete doctor.Shard;
        });
    }

    const editDoctor = (row) => {
        const auth = new Auth();
        setSelectedDoctor({ ...row, lastModifiedBy: auth.getUserId() });
        setOpenDoctorDialog(true);
    }

    useEffect(() => {
        async function fetchData() {
            setDictatorLoading(true);
            const response = await fetch('DictatorRoutes/GetDictatorRoute', {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ PartitionKey: props.match.params.partition, RowKey: props.match.params.rowkey }) // body data type must match "Content-Type" header
            });
            // console.log(data);
            const data = await response.json();
            setDictatorRoute(data);
            setRows(data.doctors);
            getAccount(data.account);
            setEditorInputValue(data.editorId);
            setProoferInputValue(data.proofer);
            if (!isGlobal) {
                getDoctor(data.account, data.dictatorId);
            }
            setDictatorLoading(false);
        }

        if (props.match.params.rowkey !== "0") {
            fetchData();
        }
        getAccounts();
        getMT();

    }, []);

    //useEffect(() => {

    //    if (rows.length > 0) {
    //        console.log("rows changed: ", rows);
    //        let doctorsState = [...rows];
    //        setDefaultDoctors(doctorsState);
    //    }
        

    //}, [rows]);

    //const setDefaultDoctors = (doctorsState) => {
    //    debugger;
    //    setDictatorRoute({ ...dictatorRoute, doctors: doctorsState });
    //}

    return (
        <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
                {isDictatorLoading && <LinearProgress />}
                <Paper className={classes.paper}>
                    <Grid>
                        {isGlobal ? 
                            <Typography variant="h5">Account Route</Typography>
                            :
                            <Typography variant="h5">Doctor Route</Typography>  
                        }
                    </Grid>
                    <Grid item xs={8}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <div>
                                    <br />
                                    <Autocomplete
                                        value={value}
                                        disabled={props.match.params.rowkey !== "0"}
                                        onChange={async (event, newValue) => {
                                            const defaultDictatorId = isGlobal ? "-1" : "";
                                            setValue(newValue);
                                            setRows([]);
                                            setDictatorRoute({ ...dictatorRoute, dictatorId: defaultDictatorId, editorId: '', account: '', editorId: '', proofer: '' });
                                            setEditorValue('');
                                            setEditorInputValue('');
                                            setProoferValue('');
                                            setProoferInputValue('');
                                            if (newValue) {

                                                await getDoctors(newValue.AccountNumber);
                                                setDictatorRoute({ ...dictatorRoute, account: newValue.AccountNumber, dictatorId: defaultDictatorId, doctors: rows, editorId: '', proofer: '' });
                                            }
                                        }}
                                        inputValue={inputValue}
                                        onInputChange={(event, newInputValue) => {
                                            setInputValue(newInputValue);
                                        }}
                                        id="account-autocomplete"
                                        options={accounts}
                                        style={{ width: 500 }}
                                        filterOptions={filterOptions}
                                        getOptionLabel={(option) => option.DataTextField ? option.DataTextField : ""}
                                        renderInput={(params) => <TextField {...params} label="Account" variant="outlined" />}
                                        disabled={props.match.params.rowkey !== "0" ? true : false  }
                                    />
                                </div>
                                <Divider className={classes.dividerPadding} variant="middle" />
                            </Grid>
                            
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="h6">Global (default) Routing</Typography>
                        <br />
                    </Grid>
                    <Grid
                        container
                        justify='flex-start'
                        spacing={2}
                    >
                        <Grid item xs={4}>
                            <Autocomplete
                                value={editorValue}
                                onChange={async (event, newValue) => {
                                    setEditorValue(newValue);
                                    setDictatorRoute({ ...dictatorRoute, editorId: '' });
                                    if (newValue) {
                                        setDictatorRoute({ ...dictatorRoute, editorId: newValue.MtId });
                                    }
                                }}
                                inputValue={editorInputValue}
                                onInputChange={(event, newInputValue) => {
                                    setEditorInputValue(newInputValue);
                                }}
                                id="editors-autocomplete"
                                options={editors}
                                filterOptions={filterOptions}
                                getOptionLabel={(option) => option.MtId ? option.MtId : ""}
                                renderInput={(params) => <TextField {...params} label="Editor" variant="outlined" />}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Autocomplete
                                value={prooferValue}
                                onChange={async (event, newValue) => {
                                    setProoferValue(newValue);
                                    setDictatorRoute({ ...dictatorRoute, proofer: '' });
                                    if (newValue) {
                                        setDictatorRoute({ ...dictatorRoute, proofer: newValue.MtId });
                                    }
                                }}
                                inputValue={prooferInputValue}
                                onInputChange={(event, newInputValue) => {
                                    setProoferInputValue(newInputValue);
                                }}
                                id="proofers-autocomplete"
                                options={proofers}
                                filterOptions={filterOptions}
                                getOptionLabel={(option) => option.MtId ? option.MtId : ""}
                                renderInput={(params) => <TextField {...params} label="Proofer" variant="outlined" />}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Typography variant="h6">Exception Routing</Typography>
                    <Grid
                        container
                        justify='flex-start'
                        spacing={1}
                    >
                        <Grid item xs={12}>

                            {/*TABLE WILL EXIST HERE*/}
                            <Grid item xs={4}>
                                <SearchBar
                                    value={searched}
                                    onChange={(searchVal) => requestSearch(searchVal)}
                                    onCancelSearch={() => cancelSearch()}
                                    placeholder="Search by Doctor Name"
                                />
                            </Grid>
                            <TableContainer className={classes.container}>
                                <TblContainer>
                                    <TblHead />
                                    <TableBody>
                                        {sortedRows.map((row, i) =>
                                            <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                                                {getRoutingColumns().map((column) => {
                                                    if (column.id === "edit") {
                                                        return (<TableCell key={column.id} align="left">
                                                            <IconButton
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() => editDoctor(row)}
                                                            >
                                                                <CreateIcon />
                                                            </IconButton>
                                                        </TableCell>);
                                                    } else {

                                                        let displayDefaultValue = false;
                                                        let defaultMessage = `Using the default ${column.id} value`;
                                                        let value = row[column.id];

                                                        if (row["routing"]) {
                                                            if (column.id == "editor" && value == "") {
                                                                value = dictatorRoute.editorId;
                                                                displayDefaultValue = true;
                                                            }

                                                            if (column.id == "proofer" && value == "") {
                                                                value = dictatorRoute.proofer;
                                                                displayDefaultValue = true;
                                                            }
                                                        }

                                                        return (

                                                            <TableCell key={column.id} align={column.align}>
                                                                {typeof value === 'boolean' ? value === true ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon /> : displayDefaultValue ? <i title={defaultMessage}>{value}</i> : value}
                                                            </TableCell>
                                                        );
                                                    }
                                                })}
                                            </TableRow>
                                        )}
                                
                                    </TableBody>
                                </TblContainer>
                                <TblPagination />
                            </TableContainer>

                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3} justify='flex-end'>
                            <Grid item>
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    onClick={() => isGlobal ? props.history.push('/accountrouting') : props.history.push('/editorrouting')}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    onClick={SaveDictatorRoute}
                                    disabled={!isEnabled || isSaving}
                                >
                                    {isSaving ? 'Saving' : 'Save'}
                                    {isSaving && 
                                        <CircularProgress 
                                            size={24} 
                                            className={classes.buttonProgress} 
                                        />
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>

            {/*START-Dialog to update doctor record*/}
            <Dialog
                open={openDoctorDialog}
                onClose={handleCloseDoctorDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle id="alert-dialog-title">
                    {"Update Doctor Route"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please fill out the form
                                    </DialogContentText>
                    <div>
                        <br />
                        {/*Doctor Route Form */}
                        <TextField required
                            id="dictatorId"
                            label="Doctor ID"
                            className={classes.textField}
                            margin="normal"
                            name="dictatorId"
                            value={ selectedDoctor.dictatorId}
                            disabled
                            
                        />
                        <TextField required
                            id="physician"
                            label="Doctor Name"
                            className={classes.textField}
                            margin="normal"
                            name="physician"
                            value={selectedDoctor.physician}
                            disabled
                        />
                        <TextField 
                            id="editor"
                            label="Editor"
                            className={classes.textField}
                            margin="normal"
                            name="editor"
                            value={selectedDoctor.editor}
                            onChange={handleOnChangeDoctor}
                        />
                        <TextField 
                            id="proofer"
                            label="Proofer"
                            className={classes.textField}
                            margin="normal"
                            name="proofer"
                            onChange={handleOnChangeDoctor}
                            value={selectedDoctor.proofer}
                        />
                        <div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedDoctor.routing}
                                        onChange={handleCheckboxOnChange}
                                        name="routing"
                                    />
                                }
                                label={'Routing?'}
                            />
                        </div>
                        <div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedDoctor.courtney}
                                        onChange={handleCheckboxOnChange}
                                        name="courtney"
                                    />
                                }
                                label={'Courtney?'}
                            />
                        </div>
                        <div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedDoctor.history}
                                        onChange={handleCheckboxOnChange}
                                        name="history"
                                    />
                                }
                                label={'History?'}
                            />
                        </div>
                        <TextField 
                            id="hasNormals"
                            label="Has Normals"
                            className={classes.textField}
                            margin="normal"
                            name="hasNormals"
                            disabled
                            value={selectedDoctor.hasNormals}

                        />
                        <TextField 
                            id="lastModified"
                            label="Last Modified"
                            className={classes.textField}
                            margin="normal"
                            name="lastModified"
                            disabled
                            value={selectedDoctor.lastModified}
                        />
                        <TextField 
                            id="lastModifiedBy"
                            label="Last Modified By"
                            className={classes.textField}
                            margin="normal"
                            name="lastModifiedBy"
                            disabled
                            value={selectedDoctor.lastModifiedBy}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseDoctorDialog}
                        color="primary"
                    >
                        Cancel
                                </Button>
                    <Button
                        onClick={handleSaveDoctor}
                        color="primary"
                        autoFocus
                    >
                        OK
                                </Button>
                </DialogActions>
            </Dialog>
            {/*END-Dialog to update doctor record*/}

            <Snackbar 
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
                open={open} 
                autoHideDuration={2000} 
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
        </Grid>
    );
}