let backendHost;

const hostname = window && window.location && window.location.hostname;

if (hostname === 'beta.snchart.com' || hostname === '10.240.11.113') {
    backendHost = 'https://webapi.snchart.com';
} else if (hostname === 'qa.snchart.com') {
    backendHost = 'https://webapi.snchart.com';
} else if (hostname.indexOf('snchart.com') > 0) {
    backendHost = 'https://webapi.snchart.com';
} else if (hostname === "localhost") {
    backendHost = "https://webapi.snchart.com";
}
else {
    backendHost = process.env.REACT_APP_BACKEND_HOST || 'https://webapi.snchart.com';
}

export const API_ROOT = `${backendHost}`;
