import React from 'react'
import {
    FormControl,
    InputLabel,
    Select as MuiSelect,
    MenuItem,
    FormHelperText
} from '@material-ui/core';

export default function Select(props) {

    const { name, label, value, size, fullWidth, disabled, variant, error = null, onChange, options } = props;

    return (
        <FormControl
            variant={variant || "outlined"}
            size={size}
            fullWidth={fullWidth}
            disabled={disabled}
            {...(error && { error: true })}
        >
            <InputLabel>{label}</InputLabel>
            <MuiSelect
                name={name}
                label={label}
                defaultValue=""
                value={value || ""}
                onChange={onChange}
            >
                {<MenuItem value="">None</MenuItem>}
                {options.map(
                    item => (
                        <MenuItem key={item.id} value={item.value ? item.value : item.id}>{item.title}</MenuItem>
                    )
                )}
            </MuiSelect>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    )
}
