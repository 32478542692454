import React, { useEffect, useState } from 'react';
import useInterval from '../hooks/useInterval';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LinearProgress from '@material-ui/core/LinearProgress';
import { CircularProgress } from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import IconButton from '@material-ui/core/IconButton';
import EvaluationTooltipCard  from "./EvaluationTooltipCard";
import moment from 'moment';

import EntityTable from "./EntityTable";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};


const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    searchInput: {
        width: '20%',
        marginTop: '20px'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    container: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },
    statusSuccess: {
        color: 'rgb(16, 124, 16)',
        fontWeight: 'bold'
    },
    status: {
        color: 'rgb(0, 0, 0)',
        fontWeight: 'bold'
    },
    disableLink: {
        pointerEvents: "none"
    }
}));

const columns = [
    { id: 'displayName', label: 'Job Id', disableSorting: false },
    { id: 'menu', label: '', disableSorting: true },
    { id: 'customModel1Name', label: 'Courtney Version', disableSorting: false },
    { id: 'status', label: 'Status', disableSorting: false },
    { id: 'shortDateTime', label: 'Created', disableSorting: false, type: 'date' }
];

export default function Tests(props) {
    const classes = useStyles();
    const [blobs, setBlobs] = useState([]);
    const [value, setValue] = React.useState("");
    const [inputValue, setInputValue] = React.useState('');
    const [isEvaluationCreating, setEvaluationCreating] = React.useState(false);
    const [isEvaluationRerun, setEvaluationRerun] = React.useState(false);
    const [isTableRefreshing, setTableRefresh] = React.useState(false);
    const [trainerInformation, setTrainerInformation] = React.useState({ status: "", name: "", id: 0, url: "", queueTime: "" });
    const [publishCourtneyInformation, setPublishCourtneyInformation] = React.useState({ status: "", name: "", id: 0, url: "", queueTime: "" });
    const [lastEvaluation, setLastEvaluation] = React.useState({ evaluationName: "", dataName: "", created: "", model1: "", model2: "", wer1: "", wer2: "", status: "" });
    const encodedString = new Buffer(":u3n6pullmiwev5kytsxitdxmwyf7muqmdaybevd4qluqfld2c4dq").toString('base64');
    const speechKey = 'adbeaf0ec72642a788aa7f9bc70c4817';
    const [open, setOpen] = useState(false);
    const [openSnack, setOpenSnack] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("warning");
    const preventDefault = (event) => event.preventDefault();
    const [rows, setRows] = useState([]);
    const [dataFetching, setDataFetching] = useState(false);
    const [openPublishDialog, setOpenPublishDialog] = useState(false);

    const handleClickOpen = () => {
        setValue("");
        setInputValue('');
        setOpen(true);
    };

    const handleClickOpenPublishDialog = () => {
        setOpenPublishDialog(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        window.location.reload(true);
        setOpenSnack(false);
    };

    const handleClosePublishDialog = () => {
        setOpenPublishDialog(false);
    };

    const handleOKPublishDialog = () => {
        triggerPublishPipeline();
        setSeverity("info");
        setMessage("The process has been triggered");
        setOpenPublishDialog(false);
        setOpenSnack(true) 
    };

    const handleSave = async () => {
        setEvaluationCreating(true);
        await createEvaluation(inputValue);
        setEvaluationCreating(false);
    };

    const handleEvaluationClick = (e, evaluationUrl) => {
        preventDefault(e);
        let id = evaluationUrl.split("evaluations/")[1];
        props.history.push(`/test/${id}`);
    };

    const fetchEvaluations = async () => {
        setDataFetching(true);
        const response = await fetch('api/Evaluations/GetEvaluations');
        const data = await response.json();
        setRows(data);
        setDataFetching(false);
    }

    const fetchAudioBlobs = async (audioName) => {
        const response = await fetch(`api/Evaluations/GetAudioBlobs?audioName=${audioName}`);
        const data = await response.json();
        setBlobs(data);
    }

    const refreshTable = async () => {
        setTableRefresh(true);
        await fetchEvaluations();
        setTableRefresh(false);
    }

     useEffect(() => {
         fetchEvaluations();
         getLastEvaluation();
    }, []);

    const createEvaluation = async (filename) => {
        const response = await fetch('api/Evaluations/CreateEvaluation', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ FileName: filename }) // body data type must match "Content-Type" header
        });
        if (response.ok) {
            setSeverity("info");
            setMessage("Successfully created Test");
            setOpen(false);
            setOpenSnack(true)
        } else {
            setSeverity("error");
            setMessage("An error has occurred, please try again");
            setOpen(false);
            setOpenSnack(true)
        }
    };

    const reRunEvaluation = async (row) => {
        await setEvaluationRerun(true);
        const response = await fetch('api/Evaluations/RerunEvaluation', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ locale: row.locale, displayName: row.displayName, model1: row.model1, model2: row.model2, dataset: row.dataset, description: "trainer", self: row.self }) // body data type must match "Content-Type" header
        });
        await setEvaluationRerun(false);
        if (response.ok) {
            setSeverity("info");
            setMessage("Successfully Rerun Test");
            setOpen(false);
            setOpenSnack(true)
        } else {
            setSeverity("error");
            setMessage("An error has occurred, please try again");
            setOpen(false);
            setOpenSnack(true)
        }
    };

    const checkTrainingPipeline = async () => {
        const response = await fetch(`https://dev.azure.com/surgicalnotes/surgicalnotes/_apis/build/definitions/70?api-version=5.1&includeLatestBuilds=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${encodedString}`
            }
        });
        const data = await response.json();
        let date = new Date(Date.parse(data.latestBuild.queueTime));
        setTrainerInformation({ status: data.latestBuild.status, id: data.latestBuild.id, url: data.latestBuild.url, name: data.name, queueTime: date });
    };

    const checkPublishPipeline = async () => {
        const response = await fetch(`https://dev.azure.com/surgicalnotes/surgicalnotes/_apis/build/definitions/82?api-version=5.1&includeLatestBuilds=true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${encodedString}`
            }
        });
        const data = await response.json();
        let date = new Date(Date.parse(data.latestBuild.queueTime));
        setPublishCourtneyInformation({ status: data.latestBuild.status, id: data.latestBuild.id, url: data.latestBuild.url, name: data.name, queueTime: date });
    };

    const triggerPipeline = async () => {
        const response = await fetch(`https://dev.azure.com/surgicalnotes/surgicalnotes/_apis/build/builds?api-version=5.1`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${encodedString}`
            },
            body: JSON.stringify({ definition: { id: 70 } })
        });
        const data = await response.json();
    };

    const triggerPublishPipeline = async () => {
        const response = await fetch(`https://dev.azure.com/surgicalnotes/surgicalnotes/_apis/build/builds?api-version=5.1`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${encodedString}`
            },
            body: JSON.stringify({ definition: { id: 82 } })
        });
        const data = await response.json();
    };

    const getLastEvaluation = async () => {
        const response = await fetch(`https://southcentralus.api.cognitive.microsoft.com/speechtotext/v3.0/evaluations`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': `${speechKey}`
            }
        });
        const data = await response.json();
        let courtneyEvaluations = data.values.filter(a => a.description === "sntrainer-evaluation-8765645");
        if (Array.isArray(courtneyEvaluations) || courtneyEvaluations.length) {
            let lastEvaluation = courtneyEvaluations[courtneyEvaluations.length -1];
            let createdValue = moment(lastEvaluation.createdDateTime).format("YYYY-MM-DD HH:mm:ss");
            let wer2Value = (lastEvaluation.properties.wordErrorRate2 + 0.01);
            let wer1Value = lastEvaluation.properties.wordErrorRate1;
            let model1url = lastEvaluation.model1.self;
            let model2url = lastEvaluation.model2.self;
            let dataseturl = lastEvaluation.dataset.self;
            

            //This can be refactored
            const datasetResponse = await fetch(`${dataseturl}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': `${speechKey}`
                }
            });
            const dataDataset = await datasetResponse.json();

            let customModels = await getCustomModels();
            let model1Match = customModels.values.find(m => m.self === model1url);
            let model2Match = customModels.values.find(m => m.self === model2url);
            const model1Value = model1Match ? model1Match.displayName : "";
            const model2Value = model2Match ? model2Match.displayName : "";
            setLastEvaluation({ evaluationName: lastEvaluation.displayName, dataName: dataDataset.displayName, created: createdValue, wer1: lastEvaluation.properties.wordErrorRate1 * 100, wer2: (wer2Value) * 100, status: (wer2Value < wer1Value) ? "Published" : "Not Published", model1: model1Value, model2: model2Value});
        }
        
    };

    const getCustomModels = async () => {
        const response = await fetch(`https://southcentralus.api.cognitive.microsoft.com/speechtotext/v3.0/models`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': `${speechKey}`
            }
        });
        const data = await response.json();
        return data;
    };


    useInterval(() => {
        checkTrainingPipeline();
        checkPublishPipeline();
    }, 10000);

    return (
        <Paper className={classes.paper}>
            <div className={classes.container}>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item>
                            <Button
                                className={classes.textField}
                                variant="contained"
                                color="primary"
                                onClick={triggerPipeline}
                                disabled={trainerInformation.status === "inProgress" || trainerInformation.status === "notStarted"}
                            >
                                {(trainerInformation.status === "inProgress" || trainerInformation.status === "notStarted") && <CircularProgress size={14} />}
                                {(trainerInformation.status !== "inProgress" || trainerInformation.status !== "notStarted") && 'Retrain Courtney'}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                className={classes.textField}
                                variant="contained"
                                color="primary"
                                onClick={handleClickOpenPublishDialog}
                                disabled={publishCourtneyInformation.status === "inProgress" || publishCourtneyInformation.status === "notStarted"}
                            >
                                {(publishCourtneyInformation.status === "inProgress" || publishCourtneyInformation.status === "notStarted") && <CircularProgress size={14} />}
                                {(publishCourtneyInformation.status !== "inProgress" || publishCourtneyInformation.status !== "notStarted") && 'Publish Courtney'}
                            </Button>
                        </Grid>
                        <Grid item>
                            <EvaluationTooltipCard
                                evaluationName={lastEvaluation.evaluationName}
                                dataName={lastEvaluation.dataName}
                                created={lastEvaluation.created}
                                model1={lastEvaluation.model1}
                                model2={lastEvaluation.model2}
                                wer1={lastEvaluation.wer1}
                                wer2={lastEvaluation.wer2}
                                status={lastEvaluation.status}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                className={classes.textField}
                                variant="contained"
                                color="primary"
                                onClick={() => handleClickOpen()}
                            >
                                Add New Test
                            </Button>
                        </Grid>
                        <Grid item >
                            <div className={classes.paper}>
                                Latest Version of Courney:
                                <b>
                                    {rows.length > 0 ? rows[0].latestModel : ""}
                                </b>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={3}
                        justify="flex-end"
                    >
                        <Grid item xs={10} >
                            <Typography variant="h6">
                                Evaluations
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <IconButton
                                aria-label="refresh"
                                onClick={refreshTable}
                            >
                                <CachedIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {isTableRefreshing && <LinearProgress />}
                            <EntityTable
                                rows={rows}
                                columnHeaders={columns}
                                reRunEvaluation={reRunEvaluation}
                                handleEvaluationClick={handleEvaluationClick}
                                fetching={dataFetching}
                            />
                            <Dialog
                                open={openPublishDialog}
                                onClose={handleClosePublishDialog}
                                aria-labelledby="alert-dialog-title-publish"
                                aria-describedby="alert-dialog-description-publish"
                                disableBackdropClick
                                disableEscapeKeyDown>
                                <DialogTitle id="alert-dialog-publish">{"This action will trigger the Courtney Publish process, Do you want to continue?"}</DialogTitle>
                                <DialogActions>
                                    <Button onClick={handleClosePublishDialog} color="primary">
                                        No
                                    </Button>
                                    <Button onClick={handleOKPublishDialog} color="primary" autoFocus>
                                        Yes
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <Dialog
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                disableBackdropClick
                                disableEscapeKeyDown
                            >
                                <DialogTitle id="alert-dialog-title">
                                    {"Add Test"}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        Please select an audio file.
                                    </DialogContentText>
                                    <div>
                                        <br />
                                        <Autocomplete
                                            value={value}
                                            onChange={(event, newValue) => {
                                                setValue(newValue);
                                            }}
                                            inputValue={inputValue}
                                            onInputChange={(event, newInputValue) => {
                                                setInputValue(newInputValue);
                                            }}
                                            id="controllable-states-demo"
                                            options={blobs}
                                            style={{ width: 500 }}
                                            renderInput={(params) =>
                                                <TextField {...params}
                                                    label="File"
                                                    variant="outlined"
                                                    onChange={(ev) => { fetchAudioBlobs(ev.target.value) }}
                                                />
                                            }
                                        />
                                    </div>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={handleClose}
                                        color="primary"
                                    >
                                        Cancel
                                </Button>
                                    <Button
                                        disabled={value === '' || value === null ? true : false}
                                        onClick={handleSave}
                                        color="primary"
                                        autoFocus
                                    >
                                        Save
                                </Button>
                                </DialogActions>
                                {isEvaluationCreating ?
                                    <div style={{
                                        backgroundColor: 'rgba(255, 255, 255, .5)',
                                        position: 'absolute',
                                        zIndex: 2000,
                                        top: 0,
                                        left: 0,
                                        bottom: 0,
                                        right: 0
                                    }}>
                                        <LinearProgress />
                                    </div>
                                    :
                                    null
                                }
                            </Dialog>
                            <Snackbar
                                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                open={openSnack}
                                autoHideDuration={2000}
                                onClose={handleCloseSnack}
                            >
                                <Alert
                                    onClose={handleCloseSnack}
                                    severity={severity}
                                >
                                    {message}
                                </Alert>
                            </Snackbar>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        {isEvaluationRerun ?
                            <div style={{
                                backgroundColor: 'rgba(255, 255, 255, .5)',
                                position: 'absolute',
                                zIndex: 2000,
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0
                            }}>
                            </div>
                            :
                            null
                        }
                    </Grid>
                </Grid>
            </div>
        </Paper>
    );
}