import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Auth from '../services/auth';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import AccountCircle from '@material-ui/icons/AccountCircle';
import CallToActionIcon from '@material-ui/icons/CallToAction';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ListSubheader from '@material-ui/core/ListSubheader';
import PublicIcon from '@material-ui/icons/Public'
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import PeopleIcon from '@material-ui/icons/People';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import HelpIcon from '@material-ui/icons/Help';
import CreateIcon from '@material-ui/icons/Create';
import userGuide from '../docs/SNChart_Admin_UserGuide.pdf';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
const drawerWidth = 240;


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appTitle: {
        flexGrow: 1,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        marginLeft: "240px",
        padding: theme.spacing(3),
        
    },
    SNBrand: {
        color: theme.palette.secondary.main
    },
    gutters: theme.mixins.gutters(),
    nested: {
        paddingLeft: theme.spacing(4),
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerContainer: {
        overflow: 'auto',
    },

}));

function ListItemLink(props) {
    return <ListItem button component={Link} {...props} />;
}

function ResponsiveDrawer(props) {
   
    const classes = useStyles();
    const auth = new Auth();
    const [open, setOpen] = React.useState(false);
    const [openMenu, setOpenMenu] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    //const { pathname } = props.location;

    const userName = auth.getUserName();

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
        setOpenMenu(true);
    };

    const handleClose = () => {

        setAnchorEl({});
        setOpenMenu(false);
    };

    const drawer = (
        <div className={classes.drawerContainer}>     
            <List component="div" disablePadding subheader={
                <ListSubheader component="div">
                    Training
                </ListSubheader>
            }>
                {['Segments'].map((text, index) => (

                    <ListItemLink key={index} to={"/"} classes={{ gutters: clsx(classes.gutters) }}>
                        <ListItemIcon><MailIcon/></ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItemLink>

                ))}
                {['Pronunciation'].map((text, index) => (

                    <ListItemLink key={index} to={"/pronunciation"} classes={{ gutters: clsx(classes.gutters) }}>
                        <ListItemIcon>  <InboxIcon /></ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItemLink>

                ))}
                {['Sentences'].map((text, index) => (

                    <ListItemLink key={index} to={"/sentences"} classes={{ gutters: clsx(classes.gutters) }}>
                        <ListItemIcon><CallToActionIcon/></ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItemLink>

                ))}

                
            </List>
            <Divider />
            <List component="div" disablePadding subheader={
                <ListSubheader component="div">
                    Substitutions
                </ListSubheader>
            }>
                {['Global'].map((text, index) => (

                    <ListItemLink key={index} to={"/globalsubstitution"} classes={{ gutters: clsx(classes.gutters) }}>
                        <ListItemIcon><PublicIcon/></ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItemLink>

                ))}
                {['Doctor'].map((text, index) => (

                    <ListItemLink key={index} to={"/doctorsubstitution"} classes={{ gutters: clsx(classes.gutters) }}>
                        <ListItemIcon><PersonIcon/></ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItemLink>

                ))}
            </List>
            <Divider />
            <List>
                <ListSubheader component="div">
                    Testing
                </ListSubheader>
                {['Tests'].map((text, index) => (

                    <ListItemLink key={index} to={"/tests"} classes={{ gutters: clsx(classes.gutters) }}>
                        <ListItemIcon><AssignmentTurnedInIcon /></ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItemLink>

                ))}
            </List>
            <Divider />
            <List component="div" disablePadding subheader={
                <ListSubheader component="div">
                    Routing
                </ListSubheader>
            }>
                {['Account'].map((text, index) => (

                    <ListItemLink key={index} to={"/accountrouting"} classes={{ gutters: clsx(classes.gutters) }}>
                        <ListItemIcon><ReceiptIcon /></ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItemLink>

                ))}
                {/*{['Doctor'].map((text, index) => (*/}

                {/*    <ListItemLink key={index} to={"/editorrouting"} classes={{ gutters: clsx(classes.gutters) }}>*/}
                {/*        <ListItemIcon><ReceiptIcon /></ListItemIcon>*/}
                {/*        <ListItemText primary={text} />*/}
                {/*    </ListItemLink>*/}

                {/*))}*/}
            </List>
            <Divider />
            <List component="div" disablePadding subheader={
                <ListSubheader component="div">
                    Administration
                </ListSubheader>
            }>
                {['Doctor Profiles'].map((text, index) => (

                    <ListItemLink key={index} to={"/doctorprofiles"} classes={{ gutters: clsx(classes.gutters) }}>
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItemLink>

                ))}
                {['Active Doctors'].map((text, index) => (

                    <ListItemLink key={index} to={"/activedoctors"} classes={{ gutters: clsx(classes.gutters) }}>
                        <ListItemIcon><PersonPinIcon /></ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItemLink>

                ))}
                {['Doctor E-Signature'].map((text, index) => (

                    <ListItemLink key={index} to={"/esignature"} classes={{ gutters: clsx(classes.gutters) }}>
                        <ListItemIcon><CreateIcon /></ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItemLink>

                ))}
                {['Settings'].map((text, index) => (

                    <ListItemLink key={index} to={"/settings"} classes={{ gutters: clsx(classes.gutters) }}>
                        <ListItemIcon><SettingsIcon /></ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItemLink>

                ))}


            </List>
            <Divider />
            <List component="div" disablePadding subheader={
                <ListSubheader component="div">
                    Help
                </ListSubheader>
            }>
                {['User Guide'].map((text, index) => (


                    <ListItemLink key={index} to={userGuide} target="_blank" classes={{ gutters: clsx(classes.gutters) }}>
                        <ListItemIcon><HelpIcon /></ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItemLink>

                ))}
                
            </List>
        </div>
    );

    const handleLogOut = () => {
        const auth = new Auth();
        auth.signOut();
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={classes.appBar}
            >
                <Toolbar>
                    {props.isAuthenticated}
                    <Typography variant="h6" noWrap className={classes.appTitle}>
                        <span className={classes.SNBrand}>SN</span>Chart Admin
                    </Typography>
                    {props.isAuthenticated && (

                        <div>
                            <Grid container spacing={2} alignItems="flex-end">
                               
                                <Grid item>
                                    <IconButton
                                        aria-owns={open ? 'menu-appbar' : null}
                                        aria-haspopup="true"
                                        onClick={handleMenu}
                                        color="inherit"
                                    >

                                        <AccountCircle />
                                    </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={openMenu}
                                        onClose={handleClose}
                                    >
                                        <MenuItem>{userName}</MenuItem>
                                        <MenuItem onClick={handleLogOut}>Log out</MenuItem>

                                    </Menu>
                                </Grid>
                            </Grid>
                        </div>

                    )}
                </Toolbar>
            </AppBar>
            {props.isAuthenticated &&
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: classes.drawerPaper
                    }}
                >
                    <Toolbar />
                    <Divider />
                    {drawer}
                </Drawer>
            }
            <main className={classes.content}>
                <Toolbar />
                {props.children}
            </main>
        </div>
    );
}

ResponsiveDrawer.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    container: PropTypes.any,
};

export default ResponsiveDrawer;
