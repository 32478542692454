import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

export default function SugestionCard() {
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Examples
        </Typography>
                
                <Typography className={classes.title} color="textSecondary">
                    {`1. Use "millimeter" as the Courtney (ASR) Phrase and "mm" as the Corrected Phrase`}
                    <br />
                    {`2. Use "Denver Health" as the Courtney (ASR) Phrase and "Denver Health Medical Center" as the Corrected Phrase`}
                    <br />
                    {`3. Use "next line" as the Courtney (ASR) Phrase and "</br>" as the Corrected Phrase`}
                    <br />
                    {`4. Use "facebook" as the Courtney (ASR) Phrase and "BLANK" as the Corrected Phrase`}
                    <br />
                   
                </Typography>
            </CardContent>
            <CardActions>
                <Typography className={classes.pos} color="textSecondary">
                    <Link href="https://download.microsoft.com/download/D/2/4/D240EBF6-A9BA-4E4F-A63F-AEB6DA0B921C/Regular%20expressions%20quick%20reference.pdf">
                        Regular Expresion Help Commands
                    </Link>
                </Typography>
            </CardActions>
        </Card>
    );
}