import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

const DocumentViewer = (props) => {

    const handleEditorChange = (content, editor) => {
        console.log('Content was updated:', content);
    }

    return (
        <Editor
            apiKey={
                "mjexfk7mq6lbufs96gtd8pohljr32x5rhs2z94a4bmnkrr66"
            }
            disabled={true}
            initialValue={ props.documentHtml }
            init={{
                height: 500,
                menubar: false,
                branding: false,
                plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                ],
            }}
            onEditorChange={handleEditorChange}
        />
    );
}

export default DocumentViewer;
