import React, { useState, useEffect } from 'react';
import DocumentViewer from './common/DocumentViewer';
import { Card, CardHeader, CardContent, CircularProgress } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { getDraftReport, getComparedDocument } from '../services/Report/reportService';

const Document = () => {
    const [doc, setDoc] = useState();
    const [loading, setLoading] = useState(false);
    const { account, jobId } = useParams();


    useEffect(() => {

        const loadData = async () => {
            setLoading(true);
            const { data: draftreport } = await getDraftReport(jobId);
            const { data: comparedResult } = await getComparedDocument(account, jobId, draftreport);
            let reportHtml = decodeURIComponent(escape(atob(comparedResult)));
            setDoc(reportHtml);
            setLoading(false);
        }

        loadData();

    }, [])

    return (
        <Card>
            <CardHeader
                title={`Document View: ${jobId}`}
            />
            <CardContent>
                {loading &&
                    <CircularProgress />
                }
                {!loading &&
                    <DocumentViewer documentHtml={doc} />
                }
            </CardContent>
        </Card>
        )
}

export default Document;