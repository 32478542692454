import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { Layout } from './components/Layout';
import './custom.css'
import Segments from './components/segments';
import Login from './components/Login';
import Segment from './components/segment';
import PrivateRoute from './components/PrivateRoute';
import Pronunciation from './components/pronunciation';
import SubstitutionsGlobal from './components/substitutionsGlobal';
import DictatorRoutes from './components/dictatorRoutes';
import Substitution from './components/substitution';
import DictatorRoute from './components/dictatorRoute';
import Sentences from './components/sentences';
import Settings from './components/Settings';
import Document from './components/Document';
import Tests from './components/Tests';
import Test from './components/Test';
import DoctorProfiles from './components/doctorProfiles';
import DoctorProfile from './components/doctorProfile';
import ActiveDoctors from './components/activeDoctors';
import ESignature from './components/esignature';
//#663795
export default class App extends Component {

  static displayName = App.name;
    render() {
      return (
          <Layout>
              <Switch>
                <PrivateRoute exact path='/' component={Segments} />
                <PrivateRoute exact path='/segment/:transcriptName' component={Segment} />
                <PrivateRoute exact path='/pronunciation' component={Pronunciation} />
                <PrivateRoute exact path='/globalsubstitution' component={SubstitutionsGlobal} isGlobal={true} />
                <PrivateRoute exact path='/globalsubstitution/:partition/:substitution' component={Substitution} />
                <PrivateRoute exact path='/doctorsubstitution' component={SubstitutionsGlobal} isGlobal={false} key={1010} />
                <PrivateRoute exact path='/doctorsubstitution/:partition/:substitution' component={Substitution} />
                <PrivateRoute exact path='/accountrouting' isGlobal={true} component={DictatorRoutes} />
                <PrivateRoute exact path='/accountrouting/:partition/:rowkey' component={DictatorRoute} />
                <PrivateRoute exact path='/editorrouting' isGlobal={false} component={DictatorRoutes} />
                <PrivateRoute exact path='/dictatorroute/:partition/:rowkey' component={DictatorRoute} />
                <PrivateRoute exact path='/sentences' component={Sentences} />
                <PrivateRoute exact path='/settings' component={Settings} />
                <PrivateRoute exact path='/document/:account/:jobId' component={Document} />
                <PrivateRoute exact path='/tests' component={Tests} />
                <PrivateRoute exact path='/test/:testid' component={Test} />
                <PrivateRoute exact path='/doctorprofiles' component={DoctorProfiles} />
                <PrivateRoute exact path='/doctorprofile/:partition/:rowkey' component={DoctorProfile} />
                <PrivateRoute exact path='/activedoctors' component={ActiveDoctors} />
                <PrivateRoute exact path='/esignature' component={ESignature} />
                <Route exact path='/login/:classic?' component={Login} />
              </Switch>
        </Layout>
      );
  }

}
