import React from 'react';
import { withStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 500,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

export default function EvaluationTooltipCard(props) {
    return (
        <div>
            <HtmlTooltip
                title={
                    <React.Fragment>
                        <Typography color="inherit">Lastest Publish Courtney</Typography>
                        <br />
                        <b>{'Evaluation Name:'}</b> {props.evaluationName}
                        <br />
                        <b>{'Created:'}</b> {props.created}
                        <br />
                        <b>{'Data Name:'}</b> {props.dataName}
                        <br />
                        <b>{'Model 1 (Current):'}</b> {props.model1} <b>{'Error Rate:'}</b> {props.wer1} {'%'}
                        <br />
                        <b>{'Model 2 (Latest):'}</b> {props.model2} <b>{'Error Rate:'}</b> {props.wer2} {'%'}
                        <br />
                        <b>{'Status:'}</b> <em>{props.status}</em>
                        <br />
                    </React.Fragment>
                }
            >
                <IconButton>
                    <InfoIcon />
                </IconButton>
            </HtmlTooltip>
        </div>
    );
}