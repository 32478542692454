import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Auth from '../services/auth';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
    container: {
        maxHeight: 440,
    },
    searchInput: {
        width: '20%',
        marginTop: '20px'
    },
    gridCard: {
        paddingTop: '20px'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
}));

export default function Pronunciation(props) {

    const classes = useStyles();
    const auth = new Auth();
    const [sentenceMeta, setSentenceMeta] = React.useState({ sasUrl: "" });
    const [sentenceContent, setSentenceContent] = React.useState({ displayForm: "", spokenForm: "" });

    const [fileContent, setFileContent] = React.useState(null);
    const userHasUpdatePermission = auth.isInRole('SNChartGlobalAdmin') || auth.isInRole('SNChartSuperUser');
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [severity, setSeverity] = React.useState("warning");
    const [isSaving, setIsSaving] = React.useState(false);
    const [isUploading, setIsUploading] = React.useState(false);

    const saveData = async (event) => {

        const expression = /[a-zA-Z][a-zA-Z ]+[a-zA-Z]$/
        if (!expression.test(sentenceContent.displayForm) || !expression.test(sentenceContent.spokenForm)) {
            console.log("NOT")
            return;
        }
        console.log("passed")
        
        setIsSaving(true);
        // console.log("saveData", transcriptEditorRef);
        //console.log(content);
        const response =  await fetch(`api/pronunciation/SavePronunciation/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify({
                displayForm: sentenceContent.displayForm,
                spokenForm: sentenceContent.spokenForm,
            })
        });

        if (response.ok) {
            setMessage("Sucessfully saved!");
            setSeverity("info");
            setOpen(true);
        } else {
            setMessage("An error has Occurred!");
            setSeverity("error");
            setOpen(true);
        }
        setIsSaving(false);
        //props.history.push('/');
        
    };

    const handleOnChange = event => {
        const { name, value } = event.target;
        // if (value === "") {
        //     setErrors([...errors, { field: name, errorMessage: "empty value" }]);
        //}
        // else {
        //    setErrors(errors.filter((error) => { return error.field !== name }))
        //}
        setSentenceContent({ ...sentenceContent, [name]: value });
    };
    useEffect(() => {
        // Update the document title using the browser API
        //document.title = `You clicked ${count} times`;
        // console.log('test');

        async function fetchData() {
            // You can await here
            console.log('fetchData');
            const response = await fetch('api/Pronunciation');
            const data = await response.json();
            // console.log(data);
            setSentenceMeta(data);
        }
        fetchData();
    }, []);

    const onFormSubmit = async (e) => {
        e.preventDefault(); // Stop form submit
        setIsUploading(true);
        const formData = new FormData();
        formData.append('file', fileContent)

        const response = await fetch(`api/Pronunciation/ReplacePronunciation/`, {
            method: 'POST',
            headers: {
                //'Content-Type': 'multipart/form-data'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData
        });

        if (response.ok) {
            setMessage("Sucessfully saved!");
            setSeverity("info");
            setOpen(true);
        } else {
            setMessage("An error has Occurred!");
            setSeverity("error");
            setOpen(true);
        }
        setIsUploading(false);
        //props.history.push('/');
    }
    const onChange = (e) => {
        setFileContent(e.target.files[0]);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);

        setSentenceContent({ displayForm: "", spokenForm: "" });
        setFileContent(null);
        document.getElementById("replace-form").reset();
       // props.history.push(`/pronunciation`);
        //props.history.pushState(null, '/');
        //props.history.pushState(null, '/pronunciation');
    };

    return (
        <Grid container className={classes.root} spacing={2}>

            <Grid item xs={12}>
                <Typography variant="h6">Pronunciation</Typography>

                <Paper className={classes.root}>
                    <Stepper orientation="vertical">
                        <Step active>
                            <StepLabel>Review</StepLabel>
                            <StepContent>
                                <Grid item xs={12}>
                                    <Typography>Download and review the current list of custom pronunciation</Typography>
                                    <Button variant="contained" color="primary" href={sentenceMeta.sasUrl} target="_blank">View custom pronunciation</Button>
                                </Grid>
                            </StepContent>
                        </Step>
                        <Step active>
                            <StepLabel>Quick Add</StepLabel>
                            <StepContent>
                                <Grid item xs={12}>
                                    <Typography>Add a new custom pronunciation</Typography>

                                    <TextField required
                                        id="newSentence"
                                        label="Display Form"
                                        className={classes.textField}
                                        margin="normal"
                                        name="displayForm"
                                        variant="outlined"
                                        helperText="i.e. PACU"
                                        onChange={handleOnChange}
                                        value={sentenceContent.displayForm}
                                        />
                                     <TextField required
                                        id="newSentence"
                                        label="Spoken Form"
                                        className={classes.textField}
                                        margin="normal"
                                        name="spokenForm"
                                        variant="outlined"
                                        helperText="i.e. Pack You"
                                        onChange={handleOnChange}
                                        value={sentenceContent.spokenForm}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2} justify="flex-end">
                                        <Grid item>
                                            <Button 
                                                variant="contained" 
                                                color="primary" 
                                                onClick={() => { saveData() }}
                                            
                                                    disabled={!userHasUpdatePermission || isSaving}
                                            >
                                                {isSaving ? 'Saving' : 'Save'}
                                                {isSaving &&
                                                    <CircularProgress
                                                        size={24}
                                                        className={classes.buttonProgress}
                                                    />
                                                }
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button 
                                                variant="contained" 
                                                color="primary" 
                                                onClick={() => { props.history.push('/'); }} 
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </StepContent>
                        </Step>
                        <Step active>
                            <StepLabel>Advanced Edit</StepLabel>
                            <StepContent>
                                <Grid item xs={12}>
                                    <Typography>Upload and replace custom pronunciations</Typography>

                                    <form id='replace-form' onSubmit={(event) => onFormSubmit(event)}>
                                        <input type="file" onChange={(event) => onChange(event)} />
                                        <Button 
                                            type="submit" 
                                            variant="contained" 
                                            color="primary"
                                            disabled={!userHasUpdatePermission || isUploading}
                                        >
                                            {isUploading ? 'Uploading' : 'Upload'}
                                            {isUploading &&
                                                <CircularProgress
                                                    size={24}
                                                    className={classes.buttonProgress}
                                                />
                                            }
                                        </Button>
                                    </form>
                                </Grid>
                            </StepContent>
                        </Step>
                    </Stepper>
                </Paper>

            </Grid>  <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
        </Grid>
    );
}
