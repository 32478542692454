import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Auth from '../services/auth';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
}));

export default function Settings(props) {
    const classes = useStyles();
    const auth = new Auth();
    const [customSettings, setCustomSettings] = React.useState({ speechModel: "", confidenceScore: 60.0 });
    const userHasUpdatePermission = auth.isInRole('SNChartGlobalAdmin') || auth.isInRole('SNChartSuperUser');

    const saveData = async (event) => {
        //console.log(content);
        await fetch(`api/CustomSettings/SaveCustomSettings/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify({ confidenceScore: parseFloat(customSettings.confidenceScore), speechModel: customSettings.speechModel })
        });
        props.history.push('/');
    };

    useEffect(() => {
        async function fetchData() {
            // You can await here
            console.log('fetchData');
            const response = await fetch('api/customSettings');
            const data = await response.json();
            //console.log(data);
            
            setCustomSettings(data);

        }
        fetchData();
    }, []);

    const handleOnChange = event => {
        const { name, value } = event.target;
        // if (value === "") {
        //     setErrors([...errors, { field: name, errorMessage: "empty value" }]);
        //}
        // else {
        //    setErrors(errors.filter((error) => { return error.field !== name }))
        //}
        //if (name === "confidenceScore") {
        //    value = parseFloat(value);
        //}
        setCustomSettings({ ...customSettings, [name]: value });
    };

    return (
        <Grid container className={classes.root} spacing={2}>

            <Grid item xs={12}>
                <Typography variant="h6">Settings</Typography>

                <Paper className={classes.root}>
                    <Grid item xs={12} md={6}>
                    <TextField
                        id="confidenceThreshold"
                        label="Training Segment Confidence Threshold"
                        className={classes.textField}
                        margin="normal"
                            name="confidenceScore"
                            variant="outlined"
                            value={customSettings.confidenceScore}
                            onChange={handleOnChange}
                        type="number"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            helperText="Training segments with a confidence score LESS than or EQUAL to this value will be imported for training"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="speechModel"
                            label="Speech Model"
                            className={classes.textField}
                            margin="normal"
                            name="speechModel"
                            onChange={handleOnChange}
                            variant="outlined"
                            fullWidth
                            value={customSettings.speechModel}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            helperText="GUID of the speech model used by Courtney"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2} justify="flex-end">

                            <Grid item>
                                <Button 
                                    className={classes.textField} 
                                    variant="contained" 
                                    color="primary" 
                                    onClick={() => { saveData() }}
                                    disabled={!userHasUpdatePermission}
                                >
                                    Save
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button 
                                    className={classes.textField} 
                                    variant="contained" color="primary" 
                                    onClick={() => { props.history.push('/'); }} 
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>

        </Grid>
    );
}