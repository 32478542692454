import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { withRouter } from "react-router";


const options = [
    'View',
    'Rerun Test'
];

const ITEM_HEIGHT = 48;

 function DotMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEvaluationClick = (evaluationUrl) => {
      let id = evaluationUrl.split("evaluations/")[1];
        props.history.push(`/test/${id}`);
    };

     const handleDraftCompareClick = (evObj) => {
        // let id = evaluationUrl.split("evaluations/")[1];
        // props.history.push(`/test/${id}`);
         let jobId = evObj.split(".")[0];
         let account = jobId.split("-")[0];
         props.history.push(`/document/${account}/${jobId}`);
     };

    return (
        <div>
            <IconButton disabled={props.currentRow.status !== "Succeeded"? true : false}
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                }}
            >
                {options.map((option) => (
                    <MenuItem key={option} onClick={() => {
                        if (option === "View") {
                            handleEvaluationClick(props.currentRow.self);
                        } else if (option === "Draft Comparison") {
                            handleDraftCompareClick(props.currentRow.displayName);
                        } else {
                            props.rerunEvaluation(props.currentRow);
                        }
                    }}>
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}

export default withRouter(DotMenu);