import React from 'react'
import { TextField } from '@material-ui/core';

export default function Input(props) {

    const { name, label, value, size, fullWidth, variant, error = null, onChange, ...other } = props;
    return (
        <TextField
            name={name}
            label={label}
            value={value}
            size={size}
            fullWidth={fullWidth}
            variant={variant || 'outlined'}
            onChange={onChange}
            {...other}
            {...(error && { error: true, helperText: error})}
        />
    )
}
