import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import SegmentsInstructionsCard from "./segmentsInstructionsCard"
import Toolbar from '@material-ui/core/Toolbar';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import { API_ROOT } from '../services/apiConfig';
import useLocalStorage from '../hooks/useLocalStorage';
import Auth from '../services/auth';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import EntityTable from "./EntityTable";

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
    searchInput: {
        width: '20%',
        marginTop: '20px'
    },
    gridCard: {
        paddingTop: '20px'
    }
});

export default function Segments(props) {

    const classes = useStyles();
    const [rows, setRows] = useState([]);
    const [filterAccounts, setFilterAccounts] = useLocalStorage('filterAccountsStorage', undefined); 
    const [activeAccounts, setActiveAccounts] = useState([]);
    const [dataFetching, setDataFetching] = useState(false);
    const columns = [
        { id: 'name', label: 'Transcript File', type: 'string' },
        { id: 'account', label: 'Account', type: 'number' },
        { id: 'job', label: 'Job', disableSorting: true },
        { id: 'createdOn', label: 'Created', type: 'date' }
    ];

    const handleTranscriptClick = (event, transcriptId) => {
        console.log(transcriptId);
        props.history.push(`/segment/${transcriptId}`);
    };

    useEffect(() => {
        setDataFetching(true);
        async function fetchData() {
            // You can await here
            console.log('fetchData');
            loadTranscriptions(filterAccounts);
            await getAccounts();
        }
        fetchData();
    }, []);

    const loadTranscriptions = async (accounts) => {

       // console.log(filterAccounts);
        setDataFetching(true);

        var accountSimpleArray = [];
        if (accounts !== undefined) {
            accountSimpleArray = accounts.map(function (item, index) {
                return item.AccountNumber;
            });
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(accountSimpleArray)
        };
        console.log(requestOptions);
        const response = await fetch('transcriptions', requestOptions);
        const data = await response.json();
        console.log(data);
        setRows(data);
        setDataFetching(false);

    }

    const getAccounts = async () => {
        const auth = new Auth();
        const response = await fetch(`${API_ROOT}/api/v1/Users/Accounts`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.getToken()}`
            }
        });
        let data = await response.json();
        let formateddata = JSON.parse(data);
        if (response.ok) {
            setActiveAccounts(formateddata);
            //setFilterAccounts(formateddata);
        }
    }

   // const activeAccounts = ["900", "11"];
    
    return (
        <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h6">Transcription Segments</Typography>
                <SegmentsInstructionsCard />
                <Paper className={classes.root}>
                    <Toolbar>
                        <Autocomplete
                           fullWidth
                            multiple
                            disabled={dataFetching}
                            id="tags-standard"
                            options={activeAccounts}
                            getOptionLabel={(option) => option.DataTextField ? option.DataTextField : ""}
                            //getOptionLabel={(option) => option.title}
                            getOptionSelected={(option, value) => option.Account1 === value.Account1}
                            onChange={(event, newValue) => {
                                setFilterAccounts(newValue);
                                loadTranscriptions(newValue);
                            }}
                            value={filterAccounts || []}
                            defaultValue={filterAccounts}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    fullWidth
                                    label="Accounts Filter"
                                    placeholder="Select one or more Account to filter the list"
                                />
                            )}
                        />
                        <Tooltip title="Export Segment Counts by Account to CSV file">
                            <IconButton aria-label="export" href="/Transcriptions/GetSegmentCount" target="_blank" disabled={dataFetching} download>
                                <ArrowDownwardIcon />
                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                    <EntityTable
                        rows={rows}
                        columnHeaders={columns}
                        handleTranscriptClick={handleTranscriptClick}
                        fetching={dataFetching}
                    />
                </Paper>
            </Grid>
        </Grid>
    );
}
