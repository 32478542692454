import React, { useState, useMemo } from 'react';
import { Table, TableHead, TableRow, TableCell, makeStyles, TablePagination, TableSortLabel } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    table: {
        marginTop: theme.spacing(3),
        '& thead th': {
            fontWeight: '600',
            color: theme.palette.primary.main
        },
        '& tbody td': {
            fontWeight: '300',
        },
        '& tbody tr:hover': {
            backgroundColor: '#f2f2f2',
            cursor: 'pointer',
        },
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

export default function useTable(rows, columnHeaders, filteredRows) {
    const classes = useStyles();
    const pages = [5, 10, 25];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [sortOrder, setSortOrder] = useState("desc");
    const [sortField, setSortField] = useState(null);
    const [sortFieldDataType, setSortFieldDataType] = useState();

    const TblContainer = props => (
        <Table className={classes.table}>
            {props.children}
        </Table>
    );

    const sortedRows = useMemo(() => {
        // Default rows without search or sort
        if (!sortField && !filteredRows) return rows.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

        // Validation to set Page = 0 when search results are less than current page
        if (page > 0 && filteredRows && filteredRows.length <= (page * rowsPerPage)) {
            setPage(0);
        };

        // Handler for Search and Sort columns
        function handleSearchAndSort(data) {
            return _.orderBy([...data], (row) => {
                if (sortFieldDataType === 'date') {
                    return moment(row[sortField], 'MM/DD/YYYY');
                } else if (sortFieldDataType === 'number') {
                    return parseInt(row[sortField], 10);
                } else {
                    return row[sortField];
                }
            }, sortOrder);
        }

        // Search results || Search results with Sort by sortField
        if (!sortField && filteredRows) {
            return filteredRows.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
        } else if (sortField && filteredRows) {
            return handleSearchAndSort(filteredRows).slice(page * rowsPerPage, (page + 1) * rowsPerPage);
        }

        // Sorting columns by sortField
        return handleSearchAndSort(rows).slice(page * rowsPerPage, (page + 1) * rowsPerPage);
    }, [rows, sortField, sortFieldDataType, sortOrder, page, rowsPerPage, filteredRows]);

    const TblHead = props => {
        return (
            <TableHead>
                <TableRow>
                    {
                        columnHeaders
                            .map(headCell => (
                                <TableCell
                                    key={headCell.id}
                                    sortDirection={sortField === headCell.id ? sortOrder : false}
                                >
                                    {
                                        headCell.disableSorting ? headCell.label :
                                            <TableSortLabel
                                                active={sortField === headCell.id}
                                                direction={sortOrder}
                                                onClick={() => {
                                                    setSortField(headCell.id);
                                                    setSortFieldDataType(headCell.type);
                                                    setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
                                                }}
                                            >
                                                {headCell.label}
                                                {
                                                    sortField === headCell.id ? (
                                                        <span className={classes.visuallyHidden}>
                                                            {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                        </span>
                                                    )
                                                        :
                                                        null
                                                }
                                            </TableSortLabel>
                                    }
                                </TableCell>
                            )
                            )
                    }
                </TableRow>
            </TableHead>
        )
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0);
    };

    const TblPagination = () => (
        <TablePagination
            component="div"
            page={page}
            rowsPerPageOptions={pages}
            rowsPerPage={rowsPerPage}
            count={filteredRows ? filteredRows.length : rows.length}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
        />
    );

    return {
        TblContainer,
        TblHead,
        TblPagination,
        sortedRows,
        filteredRows
    };
};
