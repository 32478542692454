import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Typography, IconButton } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Auth from '../services/auth';
import { API_ROOT } from '../services/apiConfig';

import EntityTable from "./EntityTable";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    container: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'left',
        color: theme.palette.text.secondary,
    }
}));
const getGlobalColumns = () => {
    return [
        { id: 'regExpression', label: 'Courtney (ASR) Phrase', disableSorting: true },
        { id: 'text', label: 'Corrected Phrase', disableSorting: true },
        { id: 'replaceAll', label: 'Replace All', disableSorting: true, disableSearch: true },
        { id: 'count', label: 'Count', disableSorting: false, disableSearch: true },
        { id: 'lastAppliedDate', label: 'Last Applied Date', disableSorting: false, disableSearch: true, type: 'date' },
        { id: 'created', label: 'Created', disableSorting: false, type: 'date' },
        { id: 'editorId', label: 'Created By', disableSorting: true }
    ];
};
const getDoctorColumns = () => {
    return [
        { id: 'regExpression', label: 'Courtney (ASR) Phrase', disableSorting: true },
        { id: 'text', label: 'Corrected Phrase', disableSorting: true },
        { id: 'account', label: 'Account', disableSorting: true },
        { id: 'dictatorId', label: 'Doctor Id', disableSorting: true },
        { id: 'replaceAll', label: 'Replace All', disableSorting: true, disableSearch: true },
        { id: 'count', label: 'Count', disableSorting: false, disableSearch: true },
        { id: 'totalJobs', label: 'Total Jobs', disableSorting: false, disableSearch: true },
        { id: 'percentage', label: '% Applied', disableSorting: false, disableSearch: true },
        { id: 'lastAppliedDate', label: 'Last Applied Date', disableSorting: false, disableSearch: true, type: 'date' },
        { id: 'created', label: 'Created', disableSorting: false, type: 'date' },
        { id: 'editorId', label: 'Created By', disableSorting: true  }
    ];
};

export default function SubstitutionsGlobal(props) {
    const classes = useStyles();
    const auth = new Auth();
    const [rows, setRows] = useState([]);
    const columns = props.isGlobal ? getGlobalColumns() : getDoctorColumns();
    const userHasUpdatePermission = (props.isGlobal && auth.isInRole('SNChartGlobalAdmin')) || !props.isGlobal;
    const [dataFetching, setDataFetching] = useState(false);

    useEffect(() => {
        async function fetchDataGlobal() {
            // You can await here
            setDataFetching(true);
            console.log('fetchData');
            const response = await fetch('substitution/GetAllGlobal');
            const data = await response.json();
            //let metadata = await getSubstitutionMetadata();
            //if (metadata.length > 0) {
            //    let globalSubstitutions = metadata.filter(a => { return a.Account === "998" });
            //    data.forEach((item) => {

            //        globalSubstitutions.forEach((sub) => {
            //            if (item.guid == sub.RowKey) {
            //                item.count = sub.CountApplied;
            //                item.totalJobs = sub.TotalAvailableJobs;
            //                return;
            //            }
            //        });

                    
            //        return this;
            //    });
            //    //let dataAfter = data;
            //    setRows(data);
            //}
            //else {
            //    setRows(data);
            //}
            setRows(data);
            setDataFetching(false);
        }

        async function fetchDataNonGlobal() {
            // You can await here
            setDataFetching(true);
            console.log('fetchData');
            const response = await fetch('substitution/GetAllNonGlobal');
            const data = await response.json();
            //let metadata = await getSubstitutionMetadata();
            //if (metadata.length > 0) {
            //    let globalSubstitutions = metadata.filter(a => { return a.Account != "998" });
            //    data.forEach((item) => {

            //        globalSubstitutions.forEach((sub) => {
            //            if (item.guid == sub.RowKey) {
            //                item.count = sub.CountApplied;
            //                item.totalJobs = sub.TotalAvailableJobs;
            //                if (sub.CountApplied != 0) {
            //                    item.percentage = `${sub.PercentageApplied}%`;
            //                }
            //                return;
            //            }
            //        });


            //        return this;
            //    });
            //    //let dataAfter = data;
            //    setRows(data);
            //}
            //else {
            //    setRows(data);
            //}
            setRows(data);
            setDataFetching(false);
        }

        if (props.isGlobal === true)
            fetchDataGlobal();
        else
            fetchDataNonGlobal();
    }, []);

    const addSubstitution = () => {
        const substitution = props.isGlobal ? "globalsubstitution" : "doctorsubstitution";
        props.history.push(`/${substitution}/new/0`, {
            isGlobal: props.isGlobal
        });
    }

    const getSubstitutionMetadata = async () => {
        const auth = new Auth();
        let qTypeParams = { Account: 'billing', Command: 'GetSubstitutionMetadata', Parameters: [] };
        const response = await fetch(`${API_ROOT}/api/v1/qtype/Query`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.getToken()}`
            },
            body: JSON.stringify(qTypeParams)
        });
        let substitutions = await response.json()

        return substitutions;
    }

    return (
        <Paper className={classes.paper}>
            <div className={classes.container}>
                <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{props.isGlobal ? "Global Substitutions" : "Doctor Substitutions" } </Typography>
                        <IconButton
                            variant="contained"
                            color="primary"
                            onClick={() => addSubstitution()}
                            disabled={!userHasUpdatePermission}
                        >
                            <AddBoxIcon/>
                        </IconButton>
                        <EntityTable
                            rows={rows}
                            columnHeaders={columns}
                            entityName={props.isGlobal ? 'globalsubstitution' : 'doctorsubstitution'}
                            updateSubEntity={userHasUpdatePermission}
                            deleteSubEntity={userHasUpdatePermission}
                            customDeletePath={'substitution/DeleteSubstitutionGlobal'}
                            fetching={dataFetching}
                        />
                    </Grid>
                </Grid>
            </div>
        </Paper>
    );
}
