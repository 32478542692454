import axios from 'axios';
import { API_ROOT } from '../apiConfig';
import Auth from '../auth';
 
const auth = new Auth();


export const getReportById = async (account, reportId) => {
    return await axios.get(`${API_ROOT}/api/v1/Transcriptions/GetDocument?account=${account}&reportId=${reportId}`,
        {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${auth.getToken()}`
            }
        }
    )
}

export const getDraftReport = async (jobId) => {
    return await axios.get(`/api/Customsettings/Drafts/${jobId}`,
        {
            headers: {
                "Content-Type": "application/json"
            }
        }
    )
}

export const getComparedDocument = async (account, jobId, draftReport) => {
    return await axios.post(`${API_ROOT}/api/v1/Transcriptions/GetComparedDocument/${account}/${jobId}`,
        {
            DraftReportString: draftReport,
            Granularity: 0
        },
        {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${auth.getToken()}`
            }
        }
    )
}