import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import { API_ROOT } from './apiConfig';
import PubSub from 'pubsub-js';

export default class Auth {

     login(username, password) {

        return this.processToken({
            'username': username,
            'password': password,
            'grant_type': 'password',
            'scope': 'qtype,sql snchart'
        });

    }
   
    processToken(authParams) {
        return new Promise((resolve, reject) => {

            axios.post(`${API_ROOT}/token`,
                qs.stringify(authParams), {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }).then(function (response) {
                
                let expiresAt = JSON.stringify((response.data.expires_in * 1000) + new Date().getTime());
                sessionStorage.setItem('access_token', response.data.access_token);
                sessionStorage.setItem('expires_at', expiresAt);
                sessionStorage.setItem('pwd_expires_in', response.data.pwd_expires_in);
               
                axios.get(`${API_ROOT}/api/v1/users/me`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${response.data.access_token}`
                        }
                    }).then(function (userResponse) {
                        let gaUser = '';
                        sessionStorage.setItem('homeAccount', userResponse.data.Account);
                        sessionStorage.setItem('secArchive', userResponse.data.secArchive);
                        sessionStorage.setItem('secMarkRead', userResponse.data.secMarkRead);
                        sessionStorage.setItem('secSave', userResponse.data.secSave);
                        sessionStorage.setItem('secSign', userResponse.data.secSign);
                        sessionStorage.setItem('userName', userResponse.data.Name);
                        sessionStorage.setItem('userId', userResponse.data.UserName);
                        sessionStorage.setItem('userType', userResponse.data.UserType);
                        sessionStorage.setItem('email', userResponse.data.Email);
                        sessionStorage.setItem('dictatorId', userResponse.data.DictatorId);
                        sessionStorage.setItem('toDate', moment().format("MM/DD/YYYY"));
                        sessionStorage.setItem('roles', JSON.stringify(userResponse.data.Roles));
                        if (userResponse.data.DictatorId) {
                            sessionStorage.setItem('isValidLogin', true);
                            gaUser = userResponse.data.UserName;
                        }
                        else {
                            //sessionStorage.setItem('isValidLogin', true);
                            sessionStorage.setItem('isValidLogin', userResponse.data.IdentityMode > 0);
                            gaUser = `${userResponse.data.Account}_${userResponse.data.LUID}`;
                        }

                        sessionStorage.setItem('gaUserId', gaUser);

                        if (userResponse.data.AuthorizedAccounts !== null && userResponse.data.AuthorizedAccounts.length) {

                            sessionStorage.setItem('centerName', userResponse.data.AuthorizedAccounts[0].Facility);
                            sessionStorage.setItem('activeAccount', userResponse.data.AuthorizedAccounts[0].Account);
                            if (userResponse.data.AuthorizedAccounts.length > 1) {
                                sessionStorage.setItem('canSwitchAccounts', "true");
                                sessionStorage.setItem('userAccounts', JSON.stringify(userResponse.data.AuthorizedAccounts));
                            }
                            else {
                                sessionStorage.setItem('canSwitchAccounts', "false");
                            }
                        } else {
                            sessionStorage.setItem('userAccounts', '[{}]');
                        }

                        PubSub.publish('AuthChanged');
                        resolve(true);
                    });
            }).catch(error => {
                reject(error);
            });
        });
    }

    isInRole(role) {
        let hasRole = false;
        let accounts = JSON.parse(sessionStorage.getItem('userAccounts') || '[{}]');
        switch (role) {
            case 'Archive':
                hasRole = sessionStorage.getItem('secArchive') === 'true';
                break;
            case 'MarkRead':
                hasRole = sessionStorage.getItem('secMarkRead') === 'true';
                break;
            case 'Save':
                hasRole = sessionStorage.getItem('secSave') === 'true';
                break;
            case 'Sign':
                hasRole = sessionStorage.getItem('secSign') === 'true';
                break;
            case 'Admin':
                hasRole = sessionStorage.getItem('homeAccount') === '998';
                break;
            case 'FacilityAdmin':
                let roles = JSON.parse(sessionStorage.getItem('roles'));
                hasRole = roles.indexOf('Administrator') > -1 || roles.indexOf('SupportAdmin') > -1;
                break;
            case 'SNChartGlobalAdmin':
                hasRole = sessionStorage.getItem('homeAccount') === '998' || sessionStorage.getItem('homeAccount') === '9302020' || accounts.some(function (account) { return account.Account === "9302020" });
                break;
            case 'SNChartSuperUser':
                hasRole = sessionStorage.getItem('homeAccount') === '10202020' || accounts.some(function (account) { return account.Account === "10202020" });
                break;
            default:
                hasRole = false;
        }
        return hasRole;
    }

    isAuthenticated() {
        var expiresAt = JSON.parse(sessionStorage.getItem('expires_at') || '{}');
        return new Date().getTime() < expiresAt;
    }

    isPasswordExpired() {
        var expiresAt = parseInt(sessionStorage.getItem('pwd_expires_in'));
        return expiresAt <= 0;
    }

    getPasswordExpiresIn() {
        return parseInt(sessionStorage.getItem('pwd_expires_in'));
    }

    clearPasswordExpExpiresIn() {
        sessionStorage.setItem('pwd_expires_in', 180);
    }

    saveUserLogin(userName) {
        sessionStorage.setItem('snuser', userName);
    }

    getUserLogin() {
        return sessionStorage.getItem('snuser');
    }

    getGaUser() {

        return sessionStorage.getItem('gaUserId');
    }

    getUserName() {
        return sessionStorage.getItem('userName');
    }

    getUserEmail() {
        return sessionStorage.getItem('email');
    }

    getUserId() {
        return sessionStorage.getItem('userId');
    }
    getUserType() {
        return sessionStorage.getItem('userType');
    }
    getFromDate() {
        return sessionStorage.getItem('fromDate');
    }
    getToDate() {
        return sessionStorage.getItem('toDate');
    }
    isValidLogin() {
        return sessionStorage.getItem('isValidLogin') === "true";
    }
    setValidLogin(valid) {
        return sessionStorage.setItem('isValidLogin', valid);
    }
    setFromDate(value) {
        sessionStorage.setItem('fromDate', value);
    }
    setToDate(value) {
        sessionStorage.setItem('toDate', value);
    }
    getDictatorId() {
        return sessionStorage.getItem('dictatorId');
    }
    getActiveAccount() {
        return sessionStorage.getItem('activeAccount');
    }
    getHomeAccount() {
        return sessionStorage.getItem('homeAccount');
    }
    setActiveAccount(account) {
        return sessionStorage.setItem('activeAccount', account);
    }
    getUserAccounts() {
        return JSON.parse(sessionStorage.getItem('userAccounts') || '[{}]');
    }
    getCenterName() {
        return sessionStorage.getItem('centerName');
    }
    canSwitchAccounts() {
        return sessionStorage.getItem('canSwitchAccounts');
    }
    getToken() {
        // Retrieves the user token from localStorage
        return sessionStorage.getItem('access_token');
    }

    signOut(reload = true) {
        // Clear user token and profile data from localStorage
        //const history = createBrowserHistory();
        sessionStorage.removeItem('access_token');
        sessionStorage.removeItem('expires_at');
        sessionStorage.removeItem('pwd_expires_in');
        sessionStorage.removeItem('userAccounts');
        //location.reload(true);
        //history.replace('/');
        if (reload) {
            window.location.reload();
        }
        else {
            PubSub.publish('AuthChanged');
        }
    }

    markNotificationsRead(notifications) {
        var userKey = sessionStorage.getItem('gaUserId');
        var readNotifications = localStorage.getItem(`${userKey}_readNotifications`) != null ? JSON.parse(localStorage.getItem("readNotifications")) : [];
        if (!Array.isArray(readNotifications)) {
            readNotifications = [];
        }
        for (var i = 0; i < notifications.length; i++) {
            if (!notifications[i].IsRead) {
                notifications[i].IsRead = true;
                readNotifications.push(notifications[i].NotificationId);
            }
        }
        localStorage.setItem(`${userKey}_readNotifications`, JSON.stringify(readNotifications));
        return notifications;
    }

    getNotifications() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ROOT}/api/v1/Users/Notifications`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${sessionStorage.getItem('access_token')}`
                    }
                }).then(function (response) {
                    var userKey = sessionStorage.getItem('gaUserId');
                    var readNotifications = localStorage.getItem(`${userKey}_readNotifications`) != null ? JSON.parse(localStorage.getItem(`${userKey}_readNotifications`)) : [];
                    if (!Array.isArray(readNotifications)) {
                        readNotifications = [];
                    }

                    const notifications = response.data.map((notification, i) => {
                        const result = Object.assign({}, notification,
                            {
                                Url: decodeURIComponent(notification.Url),
                                IsRead: readNotifications.indexOf(notification.NotificationId) > -1
                            });

                        return result;
                    });
                    resolve(notifications);
                }).catch(error => {
                    console.log(error)
                });
        });
    }

}