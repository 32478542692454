import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import Auth from '../services/auth';
import { API_ROOT } from '../services/apiConfig';

import EntityTable from "./EntityTable";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    container: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'left',
        color: theme.palette.text.secondary,
    }
}));

const columns = [
    { id: 'UserId', label: 'ID', disableSorting: true },
    { id: 'Account', label: 'Account', disableSorting: true },
    { id: 'Signature', label: 'Signature', disableSorting: true },
];

export default function ActiveDoctors(props) {
    const classes = useStyles();
    const auth = new Auth();
    const [rows, setRows] = useState([]);

    const userHasUpdatePermission = auth.isInRole('SNChartGlobalAdmin') || auth.isInRole('SNChartSuperUser');
    const [dataFetching, setDataFetching] = useState(false);

    useEffect(() => {
        async function fetchDataGlobal() {
            // You can await here
            setDataFetching(true);
            //const response = await fetch('DoctorProfiles/GetAll');
            //const data = await response.json();
            //console.log(data);

            const auth = new Auth();
            const response = await fetch(`${API_ROOT}/api/v1/Qtype/Query`, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.getToken()}`
                },
                body: JSON.stringify({ Account: "sql", Command: "GetActiveDictators", Parameters: {} })
            });
            let data = await response.json();
            data.forEach((item) => {
                if (item.UserId.includes("'")) {
                    item.UserId = item.UserId.replace(/'/g, '')
                }
                return this;
            });
            setRows(data);
            setDataFetching(false);
        }
        fetchDataGlobal();
    }, []);

    return (
        <Paper className={classes.paper}>
            <div className={classes.container}>
                <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12}>
                        {
                            <Typography variant="h6">Active Doctors</Typography>
                        }
                        
                        <EntityTable
                            rows={rows}
                            columnHeaders={columns}
                            fetching={dataFetching}
                        />
                    </Grid>
                </Grid>
            </div>
        </Paper>
    );
}