import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Auth from '../services/auth';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
    container: {
        maxHeight: 440,
    },
    searchInput: {
        width: '20%',
        marginTop: '20px'
    },
    gridCard: {
        paddingTop: '20px'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
}));

export default function Sentences(props) {

    const classes = useStyles();
    const auth = new Auth();
    const [sentenceMeta, setSentenceMeta] = React.useState({ sasUrl: "" });
    const [sentenceContent, setSentenceContent] = React.useState({ content: "" });

    const [fileContent, setFileContent] = React.useState(null);
    const userHasUpdatePermission = auth.isInRole('SNChartGlobalAdmin') || auth.isInRole('SNChartSuperUser');
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [severity, setSeverity] = React.useState("warning");
    const [isSaving, setIsSaving] = React.useState(false);
    const [isUploading, setIsUploading] = React.useState(false);

    const saveData = async (event) => {
        // console.log("saveData", transcriptEditorRef);
        //console.log(content);

        const repeat = /([a-zA-Z])\1{3}/g
        const utfchars = /[ -~]+$/u
        const uripattern = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
        console.log(utfchars)
        if (repeat.test(sentenceContent.content.trim()) || !utfchars.test(sentenceContent.content.trim()) || uripattern.test(sentenceContent.content.trim())) {
            console.log("Wrong pattern")
            return;
        }
        console.log("passes")
        
        setIsSaving(true);
        const response = await fetch(`api/sentences/SaveSentence/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify({
                content: sentenceContent.content
            })
        });
        if (response.ok) {
            setMessage("Sucessfully saved!");
            setSeverity("info");
            setOpen(true);
        } else {
            setMessage("An error has Occurred!");
            setSeverity("error");
            setOpen(true);
        }
        setIsSaving(false);
    };

    const handleOnChange = event => {
        const { name, value } = event.target;
       // if (value === "") {
       //     setErrors([...errors, { field: name, errorMessage: "empty value" }]);
        //}
       // else {
        //    setErrors(errors.filter((error) => { return error.field !== name }))
        //}
        setSentenceContent({ ...sentenceContent, [name]: value });
    };

    useEffect(() => {
        // Update the document title using the browser API
        //document.title = `You clicked ${count} times`;
        // console.log('test');

        async function fetchData() {
            // You can await here
            console.log('fetchData');
            const response = await fetch('api/sentences');
            const data = await response.json();
            // console.log(data);
            setSentenceMeta(data);
        }
        fetchData();

    }, []);

    const onFormSubmit = async (e) => {
        e.preventDefault(); // Stop form submit
        setIsUploading(true);

        const formData = new FormData();
        formData.append('file', fileContent)

        const response = await fetch(`api/sentences/ReplaceSentences/`, {
            method: 'POST',
            headers: {
                //'Content-Type': 'multipart/form-data'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData
        });
        if (response.ok) {
            setMessage("Sucessfully saved!");
            setSeverity("info");
            setOpen(true);
        } else {
            setMessage("An error has Occurred!");
            setSeverity("error");
            setOpen(true);
        }
        setIsUploading(false);
    }

    const onChange = (e) => {
        setFileContent(e.target.files[0]);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);

        setSentenceContent({ content: "" });
        setFileContent(null);
        document.getElementById("replace-form").reset();
        // props.history.push(`/pronunciation`);
        //props.history.pushState(null, '/');
        //props.history.pushState(null, '/pronunciation');
    };

    return (
        <Grid container className={classes.root} spacing={2}>

            <Grid item xs={12}>
                <Typography variant="h6">Framed Sentences</Typography>
              
                <Paper className={classes.root}>

                    <Stepper orientation="vertical">
                        <Step active>
                            <StepLabel>Review</StepLabel>
                            <StepContent>
                                <Grid item xs={12}>
                                    <Typography>Download and review the current list of framed sentences</Typography>
                                    <Button variant="contained" color="primary" href={sentenceMeta.sasUrl} target="_blank">View current sentences</Button>
                                </Grid>
                                </StepContent>
                        </Step>
                        <Step active>
                            <StepLabel>Quick Add</StepLabel>
                            <StepContent>
                                <Grid item xs={6}>
                                    <Typography>Add a new framed sentence</Typography>
                              
                                <TextField required
                                    id="newSentence"
                                    label="Framed Sentence"
                                    className={classes.textField}
                                    margin="normal"
                                    name="content"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleOnChange}
                                        value={sentenceContent.content}
                                        helperText="i.e. 'the patient underwent biopsy which showed to be negative' or 'description of procedure risks benefits and alternatives to the procedure were discussed with the patient'"
                                    />
                                    </Grid>
                                <Grid item xs={6}>
                                    <Grid container spacing={2} justify="flex-end">

                                        <Grid item>
                                            <Button 
                                                variant="contained" 
                                                color="primary" 
                                                onClick={() => { saveData() }}
                                                disabled={!userHasUpdatePermission || isSaving}
                                            >
                                                {isSaving ? 'Saving' : 'Save'}
                                                {isSaving &&
                                                    <CircularProgress
                                                        size={24}
                                                        className={classes.buttonProgress}
                                                    />
                                                }
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button 
                                                variant="contained" 
                                                color="primary" 
                                                onClick={() => { props.history.push('/'); }} 
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </StepContent>
                        </Step>
                        <Step active>
                            <StepLabel>Advanced Edit</StepLabel>
                            <StepContent>
                                <Grid item xs={12}>
                                    <Typography>Upload and replace framed sentences</Typography>
                                    <form id="replace-form" onSubmit={(event) => onFormSubmit(event)}>
                                        <input type="file" onChange={(event) => onChange(event)} />
                                        <Button 
                                            type="submit" 
                                            variant="contained" 
                                            color="primary"
                                            disabled={!userHasUpdatePermission || isUploading}
                                        >
                                            {isUploading ? 'Uploading' : 'Upload'}
                                            {isUploading &&
                                                <CircularProgress
                                                    size={24}
                                                    className={classes.buttonProgress}
                                                />
                                            }
                                        </Button>
                                    </form>
                                </Grid>
                            </StepContent>
                            </Step>
                        </Stepper>
                  
                   

                </Paper>

                
            </Grid>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
        </Grid>
    );
}
