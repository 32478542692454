import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TranscriptEditor from "@bbc/react-transcript-editor";
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Auth from '../services/auth';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    paper: {
        height: 140,
        width: 100,
    },
    control: {
        padding: theme.spacing(2),
    },
    transcriptRoot: {
        '& h2': {
            width:'100%'

        }, '& button[title="Save"]': {
            display: 'none'
        },
        '& button[title="Picture-in-picture"]': {
            display: 'none'
        },

    },
       issueSnack: {
        backgroundColor: theme.palette.secondary.main,
        maxWidth: '100%'
    }
}));

export default function Segment(props) {
	const classes = useStyles();

 
    const auth = new Auth();
    const userHasUpdatePermission = auth.isInRole('SNChartGlobalAdmin') || auth.isInRole('SNChartSuperUser');
    const [transcriptJson, setTranscriptJson] = React.useState({});
    const [editable] = React.useState(true);
    const [saved, setSaved] = React.useState(false);
    const [correctedTranscript, setCorrectedTranscript] = React.useState("");
    const [mediaUrl, setMediaUrl] = React.useState("https://localhost:44334/63-FST-502491191_s5.wav");
    const [transcriptLoaded, setTranscriptLoaded] = React.useState(false);
    const [account, setAccount] = React.useState({});
    const [recordToDelete, setRecordToDelete] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [openSaveDialog, setOpenSaveDialog] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [severity, setSeverity] = React.useState("warning");
    const [openSnack, setOpenSnack] = React.useState(false);
	//setTranscriptJson(transcriptJsonRaw);
    const transcriptEditorRef = React.createRef();
    /*const handleAutoSaveChanges = newAutoSaveData => {
        console.log("handleAutoSaveChanges", newAutoSaveData);
        //setCorrectedTranscript(newAutoSaveData.data);
      
        //const { data, ext } = newAutoSaveData;
        //this.setState({ autoSaveData: data, autoSaveExtension: ext });
        // Saving to local storage 
        //localSave(this.state.mediaUrl, this.state.fileName, data);
    };*/

    const handleClickOpen = (transcription) => {
        //console.log("On selected", transcription);
        setRecordToDelete(transcription);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOK = () => {
        RejectTranscription(recordToDelete);
    };

    const handleOKSaveDialog = () => {
        saveData();
    };

    const handleCloseSaveDialog = () => {
        setOpenSaveDialog(false);
    };

    const handleClickOpenSaveSegment = () => {

        setOpenSaveDialog(true);
    };

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        props.history.push('/');
        setOpenSnack(false);
    };

    const saveData = async (event) =>  {
       // console.log("saveData", transcriptEditorRef);

        var content = transcriptEditorRef.current.getEditorContent('txt');
       
        //console.log(content);
        const response = await fetch(`transcriptions/SaveTranscript/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body:JSON.stringify( {
                name: props.match.params.transcriptName,
                body: content.data
            })
        });

        if (response.ok) {
            setSeverity("info");
            setMessage("Successfully saved segment");
            setOpenSaveDialog(false);
            setOpenSnack(true)
        }
        else {
            setMessage("An error has Occurred!");
            setSeverity("error");
            setOpenSnack(true);
        }
        
        
    };

    const RejectTranscription = async (recordToDelete) => {

        const response = await fetch(`transcriptions/RejectTranscript/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify({
                name: recordToDelete
            })
        });

      

        if (response.ok) {
            setSeverity("info");
            setMessage("Successfully rejected segment");
            setOpen(false);
            setOpenSnack(true)
        }
        else {
            setMessage("An error has Occurred!");
            setSeverity("error");
            setOpenSnack(true);
        }

    };

   
    useEffect(() => {
        // Update the document title using the browser API
        //document.title = `You clicked ${count} times`;
        // console.log('test');


        async function fetchData() {
            // You can await here
            console.log('fetchData');
            const response = await fetch(`transcriptions/GetTranscript/${props.match.params.transcriptName}`);
            const data = await response.json();
            //console.log(data.transcript);
            var transcript = JSON.parse(data.transcript);
           // console.log(transcript);
           // var startTime = transcript.paragraphs[0].start;
            //var endTime = transcript.paragraphs[0].start + 0.1;
            //transcript.paragraphs.unshift({ id: 1, start: startTime, end: endTime, speaker: "SN", isEditable: false });
            //transcript.words.unshift({ id: transcript.words.length + 1, start: startTime, end: endTime, text: props.match.params.transcriptName, isEditable: false });
            setAccount(data.account)
            setTranscriptJson(transcript);
            setMediaUrl(data.mediaUrl);
            setTranscriptLoaded(true);

         
        }
        fetchData();

    }, []);
        return (
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={10}>

                    <Alert severity="warning">Please edit all reports verbatim (as dictated by physician).
                        <br />Reject items that are inaudible or lack medical context (background noise, conversations, etc) </Alert>

                </Grid>
                <Grid xs={2}>
                    <TextField disabled
                        id="faccount"
                        label="Account"
                        className={classes.textField}
                        margin="normal"
                        name="account"
                        value={account}
                    />
                </Grid>
                <Grid item xs={12}>
                    
                    <Grid container spacing={2} justify="flex-end" className={classes.transcriptRoot}>
                        <Grid item>
                    {transcriptLoaded && <TranscriptEditor
                                transcriptData={transcriptJson}
                                sttJsonType={'digitalpaperedit'}
                                mediaUrl={mediaUrl}
                                mediaType={'audio'}
                                isEditable={editable}
                                spellCheck={true}
                                ref={transcriptEditorRef}
                                />}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2} justify="flex-end">
                        <Grid item>
                            <Button variant="contained" color="secondary" disabled={!userHasUpdatePermission} onClick={() => { handleClickOpen(props.match.params.transcriptName) }}>Reject</Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary" disabled={!userHasUpdatePermission} onClick={() => { handleClickOpenSaveSegment() }}>Save & Complete</Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={() => { props.history.push('/'); }} >Cancel</Button>
                        </Grid>
                    </Grid>
                </Grid>
               
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{"Are you sure to reject this segment?"}</DialogTitle>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            No
                            </Button>
                        <Button onClick={handleOK} color="primary" autoFocus>
                            Yes
                             </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openSaveDialog}
                    onClose={handleCloseSaveDialog}
                    aria-labelledby="alert-save-dialog-title"
                    aria-describedby="alert-save-dialog-description">
                    <DialogTitle id="alert-save-dialog-title">{"Are you sure you want to complete this segment?"}</DialogTitle>
                    <DialogActions>
                        <Button onClick={handleCloseSaveDialog} color="primary">
                            No
                            </Button>
                        <Button onClick={handleOKSaveDialog} color="primary" autoFocus>
                            Yes
                             </Button>
                    </DialogActions>
                </Dialog>
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openSnack} autoHideDuration={2000} onClose={handleCloseSnack}>
                    <Alert onClose={handleCloseSnack} severity={severity}>
                        {message}
                    </Alert>
                </Snackbar>
            </Grid>
        );
   
}
