import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        marginBottom: 15
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

export default function SegmentsInstructionsCard() {
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <CardContent>
                <Typography component={'span'} className={classes.title} color="textSecondary">
                    {`Use 'Search Account' to filter the list of segments.  Please edit all reports verbatim (as dictated by physician).`}
                    <ul>
                        <li>Write out abbreviations in words.</li>
                        <li>Write out non-standard numeric strings in words (If doctor dictates numerical then spell it out. For example two and not 2.).</li>
                        <li>Non-alphabetic characters or mixed alphanumeric characters should be transcribed as pronounced (period, comma, semicolon).</li>
                        <li>Abbreviations that are pronounced as words shouldn't be edited (such as "radar", "laser", "RAM", or "NATO" - do not change CC to mL).</li>
                        <li>Write out abbreviations that are pronounced as separate letters with each letter separated by a space.</li>
                        <li>
                            <strong>Reject segments that are inaudible or not relevant (cross-talk).</strong>
                        </li>
                        <li>
                            <a href='https://docs.microsoft.com/en-us/azure/cognitive-services/speech-service/how-to-custom-speech-human-labeled-transcriptions' target="blank">Reference</a>
                        </li>
                    </ul>
                </Typography>
            </CardContent>
        </Card>
    );
}