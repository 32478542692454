import React, { Component } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import ResponsiveDrawer from './ResponsiveDrawer';
import IdleTimer from 'react-idle-timer';
import Auth from '../services/auth';
import PubSub from 'pubsub-js';


export class Layout extends Component {
    static displayName = Layout.name;
   

    constructor(props) {
        super(props);
        this.idleTimer = null;
        this.onIdle = this._onIdle.bind(this);
        this.handleAuthChanged = this._handleAuthChanged.bind(this);
        const auth = new Auth();

        this.state = {
            isAuthenticated: auth.isAuthenticated() && auth.isValidLogin()
        };
    }

    _onIdle(e) {

        const auth = new Auth();
        if (auth.isAuthenticated() && auth.isValidLogin()) {
            auth.signOut();
        }
        //console.log('user is idle', e)
        //console.log('last active', this.idleTimer.getLastActiveTime())
    }

    componentWillMount() {
        this.token = PubSub.subscribe('AuthChanged', this.handleAuthChanged.bind(this));
    }
    componentWillUnmount() {
        PubSub.unsubscribe(this.token);
    }

    _handleAuthChanged() {
        console.log('_handleAuthChanged');
        const auth = new Auth();


        this.setState({ isAuthenticated: auth.isAuthenticated() && auth.isValidLogin() });
    }

    render() {
        const auth = new Auth();
        const theme = createMuiTheme({
            palette: {
                primary: {
                    light: '#0690f9',
                    main: '#0568B2',
                    dark: '#03487c',
                },
                secondary: {
                    main: '#E08827',
                    contrastText: '#fff',
                },
            },
        });
        return (<MuiThemeProvider theme={theme}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <ResponsiveDrawer isAuthenticated={this.state.isAuthenticated}>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    onIdle={this.onIdle}
                    timeout={1000 * 60 * 15}>
                    {this.props.children}
                </IdleTimer>
            </ResponsiveDrawer>
          
        </MuiThemeProvider>);
    }
}
