import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Typography, Paper } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Auth from '../services/auth';
import { API_ROOT } from '../services/apiConfig';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

const filterOptions = createFilterOptions({
    limit: 1500
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: "100%"
    },
    paper: {
        height: '100%',
        width: '100%',
        padding: theme.spacing(2),
        color: theme.palette.text.secondary
    },
    control: {
        padding: theme.spacing(2),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100%',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function DoctorProfile(props) {
    const classes = useStyles();
    const [doctorProfile, setDoctorProfile] = React.useState({ account: "", dictatorId: "", editorId: "",rank: "" });
    const [errors, setErrors] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [severity, setSeverity] = React.useState("warning");
    const [isSaving, setIsSaving] = React.useState(false);
    const [accounts, setAccounts] = useState([]);
    const [value, setValue] = React.useState("");
    const [inputValue, setInputValue] = React.useState('');

    const [doctors, setDoctors] = useState([]);
    const [doctorValue, setDoctorValue] = React.useState("");
    const [doctorInputValue, setDoctorInputValue] = React.useState('');

    const handleOnChange = event => {
        const { name, value } = event.target;
        if (value === "") {
            setErrors([...errors, { field: name, errorMessage: "empty value" }]);
        }
        else {
            setErrors(errors.filter((error) => { return error.field !== name }))
        }
        setDoctorProfile({ ...doctorProfile, [name]: value });
    };

    const handlerankChange = (event) => {
        setDoctorProfile({ ...doctorProfile, rank: event.target.value });
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        props.history.push(`/doctorprofiles`);
    };

    const isEnabled = doctorProfile.account.length > 0 &&
        (doctorProfile.dictatorId.length > 0) &&
        doctorProfile.rank.length > 0;

    const SaveDoctorProfile = async () => {
        setIsSaving(true);
        const response = await fetch('DoctorProfiles/SaveDoctorProfile', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    Account: doctorProfile.account,
                    DictatorId: doctorProfile.dictatorId,
                    Rank: doctorProfile.rank,
                    RowKey: doctorProfile.rowKey ? doctorProfile.rowKey : "",
                    PartitionKey: doctorProfile.partitionKey ? doctorProfile.partitionKey : ""
                }
            )
        });
        if (response.ok) {
            setMessage("Sucessfully saved!");
            setSeverity("info");
            setOpen(true);
        } else {
            setMessage("An error has Occurred!");
            setSeverity("error");
            setOpen(true);
        }
        setIsSaving(false);
    }

    const getDoctor = async (account, dictatorId) => {
        const auth = new Auth();
        let accounts = [account];
        const response = await fetch(`${API_ROOT}/api/v1/Users/AvailableDictatorsForAccount`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.getToken()}`
            },
            body: JSON.stringify(accounts)
        });
        let dictators = await response.json();
        let result = dictators.find(a => { return a.DictatorId == dictatorId });
        if (response.ok) {
            setDoctorValue(result);
        }
    }

    const getAccount = async (accountId) => {
        const auth = new Auth();
        const response = await fetch(`${API_ROOT}/api/v1/Users/Account?accountId=${accountId}`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.getToken()}`
            }
        });
        let data = await response.json();
        let formateddata = JSON.parse(data);
        if (response.ok) {
            setValue(formateddata);
        }
    }

    const getAccounts = async () => {
        const auth = new Auth();
        const response = await fetch(`${API_ROOT}/api/v1/Users/Accounts`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.getToken()}`
            }
        });
        let data = await response.json();
        let formateddata = JSON.parse(data);
        if (response.ok) {
            setAccounts(formateddata);
        }
    }

    const getDoctors = async (account) => {
        const auth = new Auth();
        let accounts = [account];
        const response = await fetch(`${API_ROOT}/api/v1/Users/AvailableDictatorsForAccount`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.getToken()}`
            },
            body: JSON.stringify(accounts)
        });
        let data = await response.json();
        if (response.ok) {
            setDoctors(data);
        }
    }

    useEffect(() => {
        async function fetchData() {
            const response = await fetch('DoctorProfiles/GetDoctorProfile', {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ PartitionKey: props.match.params.partition, RowKey: props.match.params.rowkey }) // body data type must match "Content-Type" header
            });
            // console.log(data);
            const data = await response.json();
            setDoctorProfile(data);
            getAccount(data.account);
            getDoctor(data.account, data.dictatorId);
            
        }

        if (props.match.params.rowkey !== "0") {
            fetchData();
        }
        getAccounts();

    }, []);

    return (
        <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <Grid>
                        {
                            <Typography variant="h5">Doctor Profile</Typography>
                        }
                        <Typography>Fill in the Account, Doctor and Rank fields</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <div>
                                    <br />
                                    <Autocomplete
                                        value={value}
                                        onChange={(event, newValue) => {
                                            const defaultDictatorId = "";
                                            setValue(newValue);
                                            setDoctors([]);
                                            setDoctorInputValue('');
                                            setDoctorValue('');
                                            setDoctorProfile({ ...doctorProfile, dictatorId: defaultDictatorId, account: '' });
                                            if (newValue) {
                                                getDoctors(newValue.AccountNumber);
                                                setDoctorProfile({ ...doctorProfile, account: newValue.AccountNumber, dictatorId: defaultDictatorId });
                                            }
                                        }}
                                        inputValue={inputValue}
                                        onInputChange={(event, newInputValue) => {
                                            setInputValue(newInputValue);
                                        }}
                                        id="account-autocomplete"
                                        options={accounts}
                                        style={{ width: 500 }}
                                        filterOptions={filterOptions}
                                        getOptionLabel={(option) => option.DataTextField ? option.DataTextField : ""}
                                        renderInput={(params) => <TextField {...params} label="Account" variant="outlined" />}
                                    />
                                </div>
                                { <div>
                                    <div>
                                        <br />
                                        <Autocomplete
                                            value={doctorValue}
                                            onChange={(event, newValue) => {
                                                setDoctorValue(newValue);
                                                if (newValue) {
                                                    setDoctorProfile({ ...doctorProfile, dictatorId: newValue.DictatorId });
                                                }
                                                else {
                                                    setDoctorProfile({ ...doctorProfile, dictatorId: '' });
                                                }

                                            }}
                                            inputValue={doctorInputValue}
                                            onInputChange={(event, newInputValue) => {
                                                setDoctorInputValue(newInputValue);
                                            }}
                                            id="doctor-autocomplete"
                                            options={doctors}
                                            style={{ width: 500 }}
                                            filterOptions={filterOptions}
                                            getOptionLabel={(option) => option.Physician ? option.Physician : ""}
                                            renderInput={(params) => <TextField {...params} label="Doctor" variant="outlined" />}
                                        />
                                    </div>
                                </div>

                                }
                                <div>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="rank-outlined-label">Rank</InputLabel>
                                    <Select
                                        labelId="select-rank-label"
                                        id="rank-select"
                                        value={doctorProfile.rank}
                                        onChange={handlerankChange}
                                        label="Rank"
                                        
                                        
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value={"1"}>Easy</MenuItem>
                                        <MenuItem value={"2"}>Medium</MenuItem>
                                        <MenuItem value={"3"}>Hard</MenuItem>
                                    </Select>
                                    </FormControl>
                                    </div>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={3} justify='flex-end'>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>  props.history.push('/doctorprofiles')}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={SaveDoctorProfile}
                                    disabled={!isEnabled || isSaving}
                                >
                                    {isSaving ? 'Saving' : 'Save'}
                                    {isSaving &&
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
        </Grid>
    );
}