import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import Auth from '../services/auth';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import FormHelperText from '@material-ui/core/FormHelperText';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';

const styles = theme => ({
    root: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
        marginTop: theme.spacing(3),
    }),
    logo: {
        maxWidth: '100%',
        margin: 'auto',
        maxHeight: '40px'
    },
    page: {
        backgroundImage: `url(${"images/login_bg.jpg"})`,
        backgroundSize: 'cover',
        minHeight: 'calc(100vh - 24px)',
        marginLeft: "-240px",
    },
    center: {
        textAlign: 'center'
    },
    link: {
        textDecoration: 'none'
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative'
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    forgotLinkText: {
        marginTop: theme.spacing(1)
    }
});

function getSteps() {
    return ['Set User Name', 'Confirm Email'];
}


class Login extends React.Component {


    constructor(props) {
        super(props);
        this.login = this.login.bind(this);
        this.Auth = new Auth();

        this.state = {
            username: this.Auth.getUserLogin() || '',
            password: '',
            updateLogin: false,
            headline: 'SIGN IN',
            hasError: false,
            confirmationSent: false,
            loginErrorMessage: 'Invalid username or password.',
            emailcode: '',
            newusername: '',
            activeStep: 0,
            confirmError: '',
            confirmIdentityError: '',
            sendingConfirmation: false,
            confirmingCode: false,
            loggingIn: false
        };
    }

    componentWillMount() {

        if (this.Auth.isAuthenticated() && this.Auth.isValidLogin()) {
            let path = this.props.location.state !== undefined ? this.props.location.state.from.pathname || '/' : '/';
            this.props.history.replace(path);
        }
        else if (this.Auth.isAuthenticated()) {
            this.setState({ updateLogin: true, headline: "Update Login", newusername: this.Auth.getUserEmail() });
        }
    }

    handleNext = () => {
        const { activeStep } = this.state;

        this.setState({
            activeStep: activeStep + 1,
        });
    };


    isStepOptional = step => {
        return step === 1;
    };

    submit = (event) => {
        event.preventDefault();
        this.login();
    };

  

 
   


    handleChange = field => event => {

        const { target: { value } } = event;
        this.setState({ [field]: value });
    }

    login() {

        var history = this.props.history;
        var self = this;
        self.setState({ loggingIn: true });
        this.Auth.login(this.state.username, this.state.password)
            .then(function (response) {
                self.setState({ loggingIn: false });
              
                if (self.Auth.isValidLogin()) {
                    let path = self.props.location.state !== undefined ? self.props.location.state.from.pathname || '/' : '/';
                    console.log('history.replace', path);
                    self.props.history.replace(path);
                   
                } else {
                    self.setState({ updateLogin: true, headline: "Update Login", newusername: self.Auth.getUserEmail() });
                }
            }).catch(error => {
                self.setState({ loggingIn: false });
                let errorMessage = self.state.loginErrorMessage;

                if (error.response && error.response.data && error.response.data.error_description) {
                    if (error.response.data.error_description === "AccountLocked") {
                        errorMessage = "Maximum login attempts exceeded.  Use the Forgot username or password link to reset your password."
                    }
                    else if (error.response.data.error_description === "AccountHold") {
                        errorMessage = "Your access has been placed on hold until your account fees have been paid in full."
                    }
                }
                self.setState({ hasError: true, loginErrorMessage: errorMessage });
            });
    }

    render() {
        const { classes } = this.props;
        const steps = getSteps();
        const { activeStep, sendingConfirmation, loggingIn, confirmingCode } = this.state;

        return (<div className={classes.page}>
            <Grid container spacing={6} justify="center">
                <Grid item xs={12} sm={6} md={4}>
                    <Paper className={classes.root} elevation={4}>

                        <Grid container spacing={2}>
                          
                            <Grid item xs={12}>
                                <Grid container spacing={3} justify="center">
                                    <Grid item>
                                        <Typography variant="h5">
                                            {this.state.headline}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {!this.state.updateLogin && <Grid item xs={12}>
                                <form onSubmit={this.submit}>
                                    <TextField fullWidth
                                        name="txtUserName"
                                        id="txtUserName"
                                        label="User Name"
                                        required
                                        onChange={this.handleChange('username')}
                                        error={this.state.hasError}
                                        value={this.state.username}
                                    /><br />
                                    <TextField fullWidth
                                        name="txtPassword"
                                        id="txtPassword"
                                        type="password"
                                        required
                                        label="Password"
                                        onChange={this.handleChange('password')}
                                        onKeyPress={(ev) => {
                                            if (ev.key === 'Enter') {

                                                ev.preventDefault();
                                                this.login();
                                            }
                                        }}
                                        error={this.state.hasError}

                                    />
                                    {this.state.hasError && <FormHelperText id="name-error-text" error>{this.state.loginErrorMessage}</FormHelperText>}
                                </form>
                                
                            </Grid>}
                            {!this.state.updateLogin && <Grid item xs={12}>
                                <Grid container spacing={6} justify="center">
                                    <Grid item>
                                        <div className={classes.wrapper}>
                                            <Button disabled={loggingIn} variant="contained" onClick={() => { this.login() }}>Login</Button>
                                            {loggingIn && <CircularProgress size={24} className={classes.buttonProgress} />}
                                        </div>

                                    </Grid>
                                </Grid>
                            </Grid>}

                           

                            {this.state.updateLogin && <Grid item xs={12}>
                                <Stepper activeStep={activeStep}>
                                    {steps.map((label) => {
                                        const props = {};
                                        const labelProps = {};
                                        return (
                                            <Step key={label} {...props}>
                                                <StepLabel {...labelProps}>{label}</StepLabel>
                                            </Step>
                                        );
                                    })}
                                </Stepper>
                            </Grid>
                            }
                            <Grid item xs={12}>
                                <Grid container spacing={3} justify="center">
                                    <Grid item>
                                        <a href='tel:8004595616' className={classes.link}>
                                            <Typography align="center" variant="body2" gutterBottom>
                                                Contact support (800) 459-5616
                                            </Typography>
                                        </a>

                                    </Grid>
                                </Grid>
                            </Grid>
                 
                        </Grid>

                    </Paper>
                </Grid>
            </Grid>
        </div>);
    }

}

export default withStyles(styles)(Login);