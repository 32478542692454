import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Typography, IconButton } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Auth from '../services/auth';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import EntityTable from "./EntityTable";
import { API_ROOT } from '../services/apiConfig';
import useLocalStorage from '../hooks/useLocalStorage';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    container: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'left',
        color: theme.palette.text.secondary,
    }
}));

const getAccountColumns = () => {
    return [
        { id: 'account', label: 'Account', disableSorting: true  },
        { id: 'editorId', label: 'Editor', disableSorting: true  },
        { id: 'timestamp', label: 'Created', disableSorting: false, type: 'date'  }
    ];
};

const getDoctorColumns = () => {
    return [
        { id: 'account', label: 'Account', disableSorting: true  },
        { id: 'dictatorId', label: 'Doctor', disableSorting: true  },
        { id: 'editorId', label: 'Editor', disableSorting: true  },
        { id: 'timestamp', label: 'Created', disableSorting: false, type: 'date'  }
    ];
};

export default function DictatorRoutes(props) {
    const classes = useStyles();
    const auth = new Auth();
    const [rows, setRows] = useState([]);
    const [filterAccounts, setFilterAccounts] = useLocalStorage('filterAccountsStorageAccountListing', undefined);
    const [activeAccounts, setActiveAccounts] = useState([]);
    
    const isGlobal = props.isGlobal;
    const columns = isGlobal ? getAccountColumns() : getDoctorColumns();
    const userHasUpdatePermission = auth.isInRole('SNChartGlobalAdmin') || auth.isInRole('SNChartSuperUser');
    const [dataFetching, setDataFetching] = useState(false);

    const addDictatorRoute = () => {
        const dictatorRoute = isGlobal ? "accountrouting" : "dictatorroute";
        props.history.push(`/${dictatorRoute}/new/0`, {
            isGlobal: isGlobal
        });
    }

  

    useEffect(() => {
        async function fetchDataGlobal() {
            // You can await here
            setDataFetching(true);
            //console.log('fetchDataGlobal');
            const response = await fetch('DictatorRoutes/GetAllGlobal');
            const data = await response.json();
            //console.log(data);
            await getAccounts();
            //setFilteredRows(data);
            setRows(data);
            //getfilterAccountListing(filterAccounts)
            setDataFetching(false);
        }

        async function fetchDataNonGlobal() {
            // You can await here
            setDataFetching(true);
            //console.log('fetchDataNonGlobal');
            const response = await fetch('DictatorRoutes/GetAllNonGlobal');
            const data = await response.json();
            setRows(data);
            setDataFetching(false);
        }

        if (!isGlobal)
            fetchDataNonGlobal();
        else
            fetchDataGlobal();

        //filterAccountListing(filterAccounts);

    }, [isGlobal]);

    const getAccounts = async () => {
        const auth = new Auth();
        const response = await fetch(`${API_ROOT}/api/v1/Users/Accounts`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.getToken()}`
            }
        });
        let data = await response.json();
        let formateddata = JSON.parse(data);
        if (response.ok) {
            setActiveAccounts(formateddata);
            //setFilterAccounts(formateddata);
        }
    }

   

    const getFilterAccountListing = () => {

        let accountFilter = filterAccounts;
        if (accountFilter === undefined || accountFilter === null || accountFilter.length === 0) {
            return rows;

        }
        else {

            
            let filteredAccounts = rows.filter(a => { return accountFilter.findIndex(b => b.AccountNumber === a.account) > -1 });
         
            if (filteredAccounts !== undefined && filteredAccounts !== null) {
            
                return filteredAccounts;
            } else {
                return rows;
            }
         
        }
    }


    return (
        <Paper className={classes.paper}>
            <div className={classes.container}>
                <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12}>
                        {!props.isGlobal ?
                            <Typography variant="h6">Doctor Routes </Typography>
                            :
                            <Typography variant="h6">Account Routes </Typography>
                        }

                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            startIcon={<AddBoxIcon />}
                            onClick={() => addDictatorRoute()}
                            disabled={!userHasUpdatePermission}

                        >
                            Configure New Account
                         </Button>
                        </Grid>
                        <Grid item xs={12}>
                        {props.isGlobal && <Autocomplete
                            fullWidth
                            multiple
                            disabled={dataFetching}
                            id="tags-standard"
                            options={activeAccounts}
                            getOptionLabel={(option) => option.DataTextField ? option.DataTextField : ""}
                            //getOptionLabel={(option) => option.title}
                            getOptionSelected={(option, value) => option.Account1 === value.Account1}
                            onChange={(event, newValue) => {
                                setFilterAccounts(newValue);
                                //filterAccountListing(newValue);
                            }}
                            value={filterAccounts || []}
                            defaultValue={filterAccounts}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    fullWidth
                                    label="Accounts Filter"
                                    placeholder="Select one or more Account to filter the list"
                                />
                            )}
                        />}
                        <EntityTable
                            rows={getFilterAccountListing()}
                            columnHeaders={columns}
                            entityName={isGlobal ? "accountrouting" : "dictatorroute"}
                            customDeletePath={'DictatorRoutes/DeleteDictatorRoute'}
                            updateSubEntity={userHasUpdatePermission}
                            deleteSubEntity={userHasUpdatePermission}
                            fetching={dataFetching}
                        />
                    </Grid>
                </Grid>
            </div>
        </Paper>
    );
}