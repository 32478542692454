import React, { useEffect, useState, useMemo } from 'react';
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    makeStyles,
    TablePagination,
    TableSortLabel,
    Toolbar
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import InputAdornment from '@material-ui/core/InputAdornment';
import Controls from "./controls/Controls";
import { Search } from "@material-ui/icons";
import DotMenu from "./ThreeDotMenu";
import Link from '@material-ui/core/Link';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import LinearProgress from '@material-ui/core/LinearProgress';
import MuiAlert from '@material-ui/lab/Alert';
import { useHistory } from "react-router-dom";

import _ from 'lodash';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    table: {
        marginTop: theme.spacing(3),
        '& thead th': {
            fontWeight: '600',
            color: theme.palette.primary.main
        },
        '& tbody td': {
            fontWeight: '300',
        },
        '& tbody tr:hover': {
            backgroundColor: '#f2f2f2',
            cursor: 'pointer',
        },
    },
    statusSuccess: {
        color: 'rgb(16, 124, 16)',
        fontWeight: 'bold'
    },
    status: {
        color: 'rgb(0, 0, 0)',
        fontWeight: 'bold'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

export default function EntityTable(props) {
    const classes = useStyles();
    const [pages, setPages] = useState(props.pages || [5, 10, 25]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [sortOrder, setSortOrder] = useState("desc");
    const [sortField, setSortField] = useState(null);
    const [sortFieldDataType, setSortFieldDataType] = useState();
    const [dateFormat, setDateFormat] = useState(props.dateFormat || 'MM/DD/YYYY');
    const [filteredRows, setFilteredRows] = useState(undefined);
    const [recordToDelete, setRecordToDelete] = useState({ partitionGuid: '', guid: '', isGuid: true });
    const [open, setOpen] = useState(false);
    const [openSnack, setOpenSnack] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("warning");
    const [searchColumns, setSearchColumns] = useState({});
    // Adding actions if user has update or delete permission
    const [columnHeaders, setColumnHeaders] = useState((props.updateSubEntity || props.deleteSubEntity) ? [{ id: 'actions', label: 'Actions', disableSorting: true }, ...props.columnHeaders] : props.columnHeaders);
    let history = useHistory();

     // Sorting and Filtering
    const sortedRows = useMemo(() => {
        // When receiving new set of rows from server, this will clean the previous filtered values and filters
        if (props.fetching) {
            setFilteredRows(undefined);
            setSearchColumns({});
        }
        if ((page * rowsPerPage) > props.rows.length) setPage(0);
        // Default rows without search or sort
        if (!sortField && !filteredRows) return props.rows.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

        // Validation to set Page = 0 when search results are less than current page
        if (page > 0 && filteredRows && filteredRows.length <= (page * rowsPerPage)) {
            setPage(0);
        };

        // Handler for Search and Sort columns
        function handleSearchAndSort(data) {
            return _.orderBy([...data], (row) => {
                if (sortFieldDataType === 'date') {
                    return moment(row[sortField], dateFormat);
                } else if (sortFieldDataType === 'number') {
                    return parseInt(row[sortField], 10);
                } else {
                    return row[sortField];
                }
            }, sortOrder);
        }

        // Search results || Search results with Sort by sortField
        if (!sortField && filteredRows) {
            return filteredRows.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
        } else if (sortField && filteredRows) {
            return handleSearchAndSort(filteredRows).slice(page * rowsPerPage, (page + 1) * rowsPerPage);
        }

        // Sorting columns by sortField
        return handleSearchAndSort(props.rows).slice(page * rowsPerPage, (page + 1) * rowsPerPage);
    }, [props.rows, sortField, sortFieldDataType, sortOrder, page, rowsPerPage, filteredRows, columnHeaders]);

    // Pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0);
    };

    // Single Search
    const handleSearch = e => {
        const searchValue = e.target.value;
        if (searchValue !== '') {
            const filtered = _.filter([...props.rows], row => row[props.searchBy].indexOf(searchValue) >= 0);
            setFilteredRows(filtered);
        } else {
            setFilteredRows(undefined);
        }
    };

    // Multi column input text and date picker search
    const handleSearchByColumn = (columnName, columnType) => (e) => {
        const inputValue = e.target.value;
        let columnSearchValue;
        if (columnType && columnType === 'date') {
            columnSearchValue = moment(inputValue).isValid() && moment(inputValue).format(dateFormat).replace(/\b0/g, ''); // Removing leading zeros from dates 01 -> 1
        } else if (columnType === 'number') {
            if (inputValue !== "") {
                columnSearchValue = parseInt(inputValue, 10).toString();
            } else {
                columnSearchValue = inputValue;
            }
        } else {
            columnSearchValue = inputValue;
        }
        setSearchColumns({ ...searchColumns, [columnName]: columnSearchValue });
    }

    // Multi column select input search
    const handleSelectOptions = (columnName, columnType) => {
        // Getting unique values
        const rows = filteredRows ? filteredRows : props.rows;
        const filteredSelectOptions = [...new Set(rows.map(row => row[columnName].toString()))];

        // Sorting unique values
        const sortedSelectOptions = _.orderBy(filteredSelectOptions, (filteredOption) => {
            if (columnType === 'date') {
                return moment(filteredOption, dateFormat);
            } else if (columnType === 'number') {
                return parseInt(filteredOption, 10).toString();
            } else {
                return filteredOption;
            }
        }, 'asc');

        // Returning object for Input Select
        return sortedSelectOptions.map((filteredOption, i) => ({ id: i, value: filteredOption, title: filteredOption }));
    }

    // Searching by columns with values set in search inputs
    useEffect(() => {
        if (Object.entries(searchColumns).length === 0) return;
        const finalFiltered = _.filter([...props.rows], row =>
            Object
                .entries(searchColumns)
                .every(([key, val]) =>
                    moment(val).isValid() && row[key].indexOf(val) >= 0 ||
                    !val.length ||
                    row[key].toLowerCase().indexOf(val.toLowerCase()) >= 0));
        setFilteredRows(finalFiltered);
    }, [searchColumns]);

    // Update Row
    const updateEntity = (partitionGuid, guid) => {
        // Specific logic for Global and Doctor substitution
        const isGlobal = { isGlobal: props.entityName === 'globalsubstitution' || props.entityName === 'accountrouting' };
        history.push(`/${props.entityName}/${partitionGuid}/${guid}`, isGlobal !== undefined ? isGlobal : undefined);
        //props.history.push(`/${dictatorRoute}/${partitionKey}/${rowKey}`
    }

    // Delete Row
    const deleteEntity = async (partitionGuid, guid, isGuid) => {
        let reqBody;
        isGuid ? reqBody = { PartitionGuid: partitionGuid, Guid: guid } : reqBody = { PartitionKey: partitionGuid, RowKey: guid };
        const response = await fetch(props.customDeletePath ? props.customDeletePath : `/${props.entityName}/${partitionGuid}/${guid}`, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(reqBody)
        });

        if (response.ok) {
            setSeverity("info");
            setMessage("Successfully deleted record");
            setOpen(false);
            setOpenSnack(true)
        }
    }

    // Dialog
    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpenSnack(false);
        history.push('/');
    };

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    return (
        <>
            <br />
            {!props.fetching && props.searchBy &&
                <Toolbar>
                    <Controls.Input
                        label={props.searchInputLabel || 'Search'}
                        className={classes.searchInput}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            )
                        }}
                        onChange={handleSearch}
                    />
                </Toolbar>
            }
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        {columnHeaders.map(headCell => (
                                <TableCell
                                    key={headCell.id}
                                    sortDirection={sortField === headCell.id ? sortOrder : false}
                                >
                                    {headCell.disableSorting ? headCell.label :
                                            <TableSortLabel
                                                active={sortField === headCell.id}
                                                direction={sortOrder}
                                                onClick={() => {
                                                    setSortField(headCell.id);
                                                    setSortFieldDataType(headCell.type);
                                                    setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
                                                }}
                                            >
                                                {headCell.label}
                                                {sortField === headCell.id ? (
                                                        <span className={classes.visuallyHidden}>
                                                            {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                        </span>
                                                    ) : null
                                                }
                                            </TableSortLabel>
                                    }
                                </TableCell>
                            ))
                        }
                    </TableRow>
                    <TableRow>
                        {!props.fetching &&
                            columnHeaders.map((headCell) => (
                                <TableCell
                                    key={headCell.id}
                                    sortDirection={sortField === headCell.id ? sortOrder : false}
                                >
                                    {headCell.label &&
                                        headCell.id !== 'actions' &&
                                        !headCell.disableSearch &&
                                        ((((headCell.type === undefined || headCell.type === 'string' || headCell.type === 'number') && !headCell.search) &&
                                            <Controls.Input
                                                name={headCell.label}
                                                variant={(!headCell.label || headCell.disableSearch) && 'filled'}
                                                size={headCell.size || 'small'}
                                                fullWidth={headCell.fullWidth || true}
                                                onChange={handleSearchByColumn(headCell.id, headCell.type)}
                                                value={!searchColumns[headCell.id] ? '' : searchColumns[headCell.id]}
                                            />)
                                        || (headCell.type && headCell.type === 'date' &&
                                            <Controls.DatePicker
                                                name={headCell.label}
                                                variant={(!headCell.label || headCell.disableSearch) && 'filled'}
                                                size={headCell.size || 'small'}
                                                fullWidth={headCell.fullWidth || true}
                                                onChange={handleSearchByColumn(headCell.id, headCell.type)}
                                                value={!searchColumns[headCell.id] ? null : (moment(searchColumns[headCell.id]).isValid() && moment(searchColumns[headCell.id]) || null)}
                                            />
                                        || (headCell.search && headCell.search === 'select' &&
                                            <Controls.Select
                                                name={headCell.label}
                                                variant={(!headCell.label || headCell.disableSearch) && 'filled'}
                                                size={headCell.size || 'small'}
                                                fullWidth={headCell.fullWidth || true}
                                                value={(searchColumns[headCell.id] !== '' && searchColumns[headCell.id])}
                                                onChange={handleSearchByColumn(headCell.id)}
                                                options={handleSelectOptions(headCell.id, headCell.type)}
                                            />
                                        )
                                        ))
                                    }
                                </TableCell>
                            ))
                        }
                    </TableRow>
                </TableHead>
                {props.fetching ?
                    <tbody>
                        <tr>
                            <td colSpan="100%">
                                <LinearProgress />
                            </td>
                        </tr>
                    </tbody>
                    :
                    <TableBody>
                        {sortedRows.map((row, i) => {
                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={i.toString()}
                                    onClick={props.handleTranscriptClick ? (e) => props.handleTranscriptClick(e, row[columnHeaders[0].id]) : undefined}
                                >
                                    {props.children ? props.children :
                                        columnHeaders.map((column) => {
                                            if (column.id === "actions") {
                                                return <TableCell key={column.id} align="center" style={{ width: '10%' }}>
                                                    {props.updateSubEntity && <IconButton
                                                        variant="contained"
                                                        style={{ paddingLeft: '10px', paddingRight: '15px' }}
                                                        size="small"
                                                        color="primary"
                                                        onClick={() => updateEntity(row.partitionGuid ? row.partitionGuid : row.partitionKey, row.guid ? row.guid : row.rowKey)}
                                                        disabled={props.userHasUpdatePermission && !props.userHasUpdatePermission}
                                                    >
                                                        <CreateIcon />
                                                    </IconButton>}
                                                    {props.deleteSubEntity && <IconButton
                                                        variant="contained"
                                                        style={{ paddingLeft: '15px', paddingRight: '10px' }}
                                                        size="small"
                                                        color="primary"
                                                        onClick={() => {
                                                            setRecordToDelete({
                                                                partitionGuid: row.partitionGuid ? row.partitionGuid : row.partitionKey,
                                                                guid: row.guid ? row.guid : row.rowKey,
                                                                isGuid: row.partitionGuid !== undefined
                                                            }); setOpen(true)
                                                        }}
                                                        disabled={props.userHasUpdatePermission && !props.userHasUpdatePermission}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>}
                                                </TableCell>;
                                            } else if (column.id === "deleste") {
                                                return (<TableCell key={column.id} align="left">
                                                    <IconButton
                                                        variant="contained"
                                                        size="small"
                                                        color="primary"
                                                        onClick={() => {
                                                            setRecordToDelete({
                                                                partitionGuid: row.partitionGuid ? row.partitionGuid : row.partitionKey,
                                                                guid: row.guid ? row.guid : row.rowKey,
                                                                isGuid: row.partitionGuid !== undefined
                                                            }); setOpen(true)
                                                        }}
                                                        disabled={!props.userHasUpdatePermission}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>);
                                            } else if (column.id === "menu") {
                                                const innerRow = row;
                                                return (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                    >
                                                        <DotMenu
                                                            currentRow={innerRow}
                                                            reRunEvaluation={props.reRunEvaluation ? props.reRunEvaluation : undefined}
                                                        />
                                                    </TableCell>
                                                );
                                            } else if (column.id === "displayName") {
                                                const innerRow = row;
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        <Link
                                                            className={innerRow.status !== "Succeeded" ? classes.disableLink : ""}
                                                            href="#"
                                                            onClick={props.handleEvaluationClick ? (e) => props.handleEvaluationClick(e, innerRow.self) : undefined}
                                                        >
                                                            {value}
                                                        </Link>
                                                    </TableCell>
                                                );
                                            } else if (column.id === "status") {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        className={value === "Succeeded" ? classes.statusSuccess : classes.status}
                                                    >
                                                        {value}
                                                    </TableCell>
                                                );
                                            } else {
                                                const value = row[column.id];
                                                if (typeof value === 'boolean') {
                                                    return (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                        >
                                                            {typeof value === 'boolean' ? value === true ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon /> : value}
                                                        </TableCell>
                                                    );
                                                } else {
                                                    return (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                        >
                                                            {column.format && typeof value === 'number' ? column.format(value) : value}
                                                        </TableCell>
                                                    );
                                                }
                                            }
                                        })
                                    }
                                </TableRow>
                            );
                        })}
                    </TableBody>
                }
            </Table>
            {!props.fetching &&
                <TablePagination
                    component="div"
                    page={page}
                    rowsPerPageOptions={pages}
                    rowsPerPage={rowsPerPage}
                    count={filteredRows ? filteredRows.length : props.rows.length}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            }
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure to delete this record?"}
                </DialogTitle>
                <DialogActions>
                    <Button
                        onClick={() => setOpen(false)}
                        color="primary"
                    >
                        No
                    </Button>
                    <Button
                        onClick={() => deleteEntity(recordToDelete.partitionGuid, recordToDelete.guid, recordToDelete.isGuid)}
                        color="primary"
                        autoFocus
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnack}
                autoHideDuration={2000}
                onClose={handleCloseSnack}
            >
                <Alert
                    onClose={handleCloseSnack}
                    severity={severity}
                >
                    {message}
                </Alert>
            </Snackbar>
        </>
    );
};

/********************************************************************************************************************
 EntityTable Component:
 It receives rows and columns parameters to render the data inside a table.

 Parameters:
    rows: PropTypes.array (Required) - Array of data to display
    columnHeaders: PropTypes.object (Required) - Column headings to display, how to use:
        const columns = [
            { id: 'name', label: 'Transcript File', type: 'string' },
            { id: 'account', label: 'Account', type: 'number', search: 'select' },
            { id: 'job', label: 'Job', disableSorting: true },
            { id: 'createdOn', label: 'Created', type: 'date' }
        ];
        disableSorting: Disable the sorting for the column
        disableSearch: Disable search for the column
        search: 'select' displays a select input instead of input text
        fullWidth: Set the width for the input to use the whole row cell
    searchBy: PropTypes.string (Optional) - Column name use to display Search input and searching through the data
    searchInputLabel: PropTypes.string (Optional) - Label to display in Search Input
    userHasUpdatePermission: PropTypes.bool (Optional) - Permission to disable update/delete buttons
    entityName: PropTypes.string (Optional) - Name of the entity used most for history push in url path
    pages: Number of records by page to display in pagination, default value: [5, 10, 25]
    dateFormat: Date format for dates values, default value: 'MM/DD/YYYY'
    customDeletePath: PropTypes.string (Optional) - Custom path for delete endpoint
    fetching: PropTypes.bool (Optional) - Loading state for Table component

 - A nice to have in future we can improve how specific functions are handled without using props -
    reRunEvaluation: PropTypes.fn (Optional) - Specific function of Tests Page
    handleEvaluationClick: PropTypes.fn (Optional) - Specific function of Tests Page
    handleTranscriptClick: PropTypes.fn (Optional) - Specific function of Segments Page
********************************************************************************************************************/