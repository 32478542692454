import React, { useEffect ,useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Typography, Paper } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Auth from '../services/auth';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import SugestionCard from '../components/suggestionCard';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { API_ROOT } from '../services/apiConfig';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

const filterOptions = createFilterOptions({
    limit: 1500
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: "100%"
    },
    paper: {
        height: '100%',
        width: '100%',
        padding: theme.spacing(2),
        color: theme.palette.text.secondary
    },
    control: {
        padding: theme.spacing(2),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100%',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}));

export default function Substitution(props) {
    const classes = useStyles();
    const substitutionType = [
        { value: 'Literal', label: 'Literal' },
        { value: 'RegEx', label: 'Regular Expression' }];
    const isGlobal = props.location.state.isGlobal;
    const [substitutionglobalJson, setSubstitutionglobal] = React.useState({ text: "", regExpression: "", account: "", dictatorId: "", type: substitutionType[0].value});
    const [errors, setErrors] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [severity, setSeverity] = React.useState("warning");
    const [isSaving, setIsSaving] = React.useState(false);
    const [accounts, setAccounts] = useState([]);
    const [value, setValue] = React.useState("");
    const [inputValue, setInputValue] = React.useState('');
    const [doctors, setDoctors] = useState([]);
    const [doctorValue, setDoctorValue] = React.useState("");
    const [doctorInputValue, setDoctorInputValue] = React.useState('');
    const [duplicateSubstitution, setDuplicateSubstitution] = React.useState(false);

    const getDoctor = async (account, dictatorId) => {
        const auth = new Auth();
        let accounts = [account];

        if (dictatorId == "ALL") {
            setDoctorValue({ DictatorId: "ALL", Physician: "All Doctors for Account", Account: account });
            return;
        }

        const response = await fetch(`${API_ROOT}/api/v1/Users/AvailableDictatorsForAccount`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.getToken()}`
            },
            body: JSON.stringify(accounts)
        });
        let dictators = await response.json();
        let result = dictators.find(a => { return a.DictatorId == dictatorId });
        if (response.ok) {
            setDoctorValue(result);
        }
    }

    const getAccount = async (accountId) => {
        const auth = new Auth();
        const response = await fetch(`${API_ROOT}/api/v1/Users/Account?accountId=${accountId}`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.getToken()}`
            }
        });
        let data = await response.json();
        let formateddata = JSON.parse(data);
        if (response.ok) {
            setValue(formateddata);
        }
    }

    const getAccounts = async () => {
        const auth = new Auth();
        const response = await fetch(`${API_ROOT}/api/v1/Users/Accounts`, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.getToken()}`
            }
        });
        let data = await response.json();
        let formateddata = JSON.parse(data);
        if (response.ok) {
            setAccounts(formateddata);
        }
    }

    const getDoctors = async (account) => {
        const auth = new Auth();
        let accounts = [account];
        const response = await fetch(`${API_ROOT}/api/v1/Users/AvailableDictatorsForAccount`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.getToken()}`
            },
            body: JSON.stringify(accounts)
        });
        let data = await response.json();
        if (response.ok) {
            //console.log(data);
          
            data.unshift({ DictatorId: "ALL", Physician: "All Doctors for Account", Account: data[0].Account });
            setDoctors(data);
        }
    }

    useEffect(() => {
        async function fetchData() {

            const response = await fetch('substitution/GetSubstitutionGlobal', {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ PartitionGuid: props.match.params.partition, Guid: props.match.params.substitution }) // body data type must match "Content-Type" header
            });
            // console.log(data);
            const data = await response.json();
            setSubstitutionglobal(data);
            getAccount(data.account);
            if (!isGlobal) {
                getDoctor(data.account, data.dictatorId);
            }
        }

        if (props.match.params.substitution !== "0") {
            fetchData();
        }
        getAccounts();

    }, []);

    const handleOnChange = event => {
        const { name, value } = event.target;
        if (value === "") {
            setErrors([...errors, { field: name, errorMessage: "empty value" }]);
        }
        else
        {
            setErrors(errors.filter((error) => {return error.field !== name }))
        }
        setSubstitutionglobal({ ...substitutionglobalJson, [name]: value });
    };

    const handleOnChangeReplace = event => {
        const { name, checked } = event.target;
        setSubstitutionglobal({ ...substitutionglobalJson, [name]: checked });
    };

    const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
        }
        setOpen(false);
        if (duplicateSubstitution) {
            return;
        }
        else if (isGlobal) {
            props.history.push(`/globalsubstitution`);
        }
        else {
            props.history.push(`/doctorsubstitution`);
        }
    };

    const validateFieldsGlobal = () => {
        return (substitutionglobalJson.regExpression !== "" && 
        substitutionglobalJson.text !== "" );
    }

    const validateFieldsDoctors = () => {
        return (substitutionglobalJson.regExpression !== "" && 
        substitutionglobalJson.text !== "" && 
        substitutionglobalJson.account !== "" && 
        substitutionglobalJson.dictatorId !== "");
    }

    const validateDuplicateSubstitution =  async () => {
        const response = await fetch('substitution/CheckDuplicate', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                Text: substitutionglobalJson.text,
                RegExpression: substitutionglobalJson.regExpression,
                IsGlobal: isGlobal,
                DictatorId: isGlobal ? "-1" : substitutionglobalJson.dictatorId,
                Account: isGlobal ? "998" : substitutionglobalJson.account
            }) // body data type must match "Content-Type" header
        });

        const data = await response.json();
        return data;
    }


    const isEnabled = substitutionglobalJson.regExpression.length > 0 && 
        substitutionglobalJson.text.length > 0 &&
        (substitutionglobalJson.account.length > 0 || isGlobal) &&
        (substitutionglobalJson.dictatorId.length > 0 || isGlobal);

    const SaveSubstitution = async () => {
        if (props.match.params.partition === "new") {
            setIsSaving(true);
            const isDuplicate = await validateDuplicateSubstitution();
            if (isDuplicate) {
                setDuplicateSubstitution(true);
                setMessage("The substitution is duplicated. Please verify the content.");
                setSeverity("error");
                setOpen(true);
                setIsSaving(false);
                return;
            }
        }
        setDuplicateSubstitution(false);
        setIsSaving(true);
        const auth = new Auth();
        const response = await fetch('substitution/SaveSubstitutionGlobal', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    Account: isGlobal ? "998" : substitutionglobalJson.account,
                    DictatorId: isGlobal ? "-1" : substitutionglobalJson.dictatorId,
                    EditorId:  auth.getUserId(),
                    RegExpression: substitutionglobalJson.regExpression,
                    Text: substitutionglobalJson.text,
                    ReplaceAll: substitutionglobalJson.replaceAll ? substitutionglobalJson.replaceAll : false,
                    Guid: substitutionglobalJson.guid ? substitutionglobalJson.guid : "",
                    PartitionGuid: substitutionglobalJson.partitionGuid ? substitutionglobalJson.partitionGuid : "",
                    Type: substitutionglobalJson.type,
                    Count: substitutionglobalJson.count,
                    LastAppliedDate: substitutionglobalJson.lastAppliedDate
                }
            )
        });
        if (response.ok) {
            setMessage("Sucessfully saved!");
            setSeverity("info");
            setOpen(true);
        } else {
            setMessage("An error has Occurred!");
            setSeverity("error");
            setOpen(true);
        }
        setIsSaving(false);
    }

    return (
        <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <Grid>
                        <Typography variant="h5">{isGlobal ? "Global Substitution" : "Doctor Substitution"}</Typography>
                        <Typography></Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <SugestionCard/>
                    </Grid>
                    <Grid item xs={8}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField required
                                    id="fexpression"
                                    label="Courtney (ASR) Phrase"
                                    className={classes.textField}
                                    margin="normal"
                                    name="regExpression"
                                    value={substitutionglobalJson.regExpression}
                                    onChange={handleOnChange}
                                    error={substitutionglobalJson.regExpression === ""}
                                    helperText={substitutionglobalJson.regExpression === "" ? 'This field should not be empty' : ''}
                                />
                                <TextField required
                                    id="fsubstitution"
                                    label="Corrected Phrase"
                                    className={classes.textField}
                                    margin="normal"
                                    name="text"
                                    value={substitutionglobalJson.text}
                                    onChange={handleOnChange}
                                    error={substitutionglobalJson.text === ""}
                                    helperText={substitutionglobalJson.text === "" ? 'This field should not be empty' : ''}
                                />
                                {!isGlobal && <div>
                                    <br />
                                    <Autocomplete
                                        value={value}
                                        onChange={(event, newValue) => {
                                            const defaultDictatorId = isGlobal ? "-1" : "";
                                            setValue(newValue);
                                            setDoctors([]);
                                            setDoctorInputValue('');
                                            setDoctorValue('');
                                            setSubstitutionglobal({ ...substitutionglobalJson, dictatorId: defaultDictatorId, account: '' });
                                            if (newValue) {
                                                getDoctors(newValue.AccountNumber);
                                                setSubstitutionglobal({ ...substitutionglobalJson, dictatorId: defaultDictatorId, account: newValue.AccountNumber });
                                            }
                                        }}
                                        inputValue={inputValue}
                                        onInputChange={(event, newInputValue) => {
                                            setInputValue(newInputValue);
                                        }}
                                        id="account-autocomplete"
                                        options={accounts}
                                        style={{ width: 500 }}
                                        filterOptions={filterOptions}
                                        getOptionLabel={(option) => option.DataTextField ? option.DataTextField : ""}
                                        renderInput={(params) => <TextField {...params} label="Account" variant="outlined" />}
                                    />
                                </div>}
                                {!isGlobal && <div>
                                    <div>
                                        <br />
                                        <Autocomplete
                                            value={doctorValue}
                                            onChange={(event, newValue) => {
                                                setDoctorValue(newValue);
                                                if (newValue) {
                                                    setSubstitutionglobal({ ...substitutionglobalJson, dictatorId: newValue.DictatorId });
                                                }
                                                else {
                                                    setSubstitutionglobal({ ...substitutionglobalJson, dictatorId: '' });
                                                }

                                            }}
                                            inputValue={doctorInputValue}
                                            onInputChange={(event, newInputValue) => {
                                                setDoctorInputValue(newInputValue);
                                            }}
                                            id="doctor-autocomplete"
                                            options={doctors}
                                            style={{ width: 500 }}
                                            filterOptions={filterOptions}
                                            getOptionLabel={(option) => option.Physician ? option.Physician : ""}
                                            renderInput={(params) => <TextField {...params} label="Doctor" variant="outlined" />}
                                        />
                                    </div>
                                </div>}
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-error-label">Type</InputLabel>
                                    <Select
                                        id="demo-simple-select-error"
                                        name="type"
                                        value={substitutionglobalJson.type}
                                        onChange={handleOnChange}
                                    >
                                        {substitutionType.map(s => {
                                            return <MenuItem value={s.value}>{s.label}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>

                                <Grid item xs={6}>
                                    <FormControlLabel style={{ margin: 20 }}
                                        control={
                                            <Switch
                                                name="replaceAll"
                                                value={substitutionglobalJson.replaceAll || false}
                                                checked={substitutionglobalJson.replaceAll || false}
                                                onChange={handleOnChangeReplace}
                                            />
                                        }
                                        label={<Typography>Replace All (instances found within a job or Operative Report)</Typography>}
                                    />

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={3} justify='flex-end'>
                            <Grid item>
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    onClick={() => { isGlobal ? props.history.push('/globalsubstitution') : props.history.push('/doctorsubstitution') }}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    onClick={SaveSubstitution}
                                    disabled={!isEnabled || isSaving}
                                >
                                    {isSaving ? 'Saving' : 'Save'}
                                    {isSaving && 
                                        <CircularProgress 
                                            size={24} 
                                            className={classes.buttonProgress} 
                                        />
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Snackbar 
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
                open={open} 
                autoHideDuration={2000} 
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={ severity}>
                    {message}
                </Alert>
            </Snackbar>
        </Grid>    
    );
}